.pool-info {
  background-color: var(--body-bg-color);
  width: 100%;
  margin-top: 5rem;
  padding: 4rem;
  overflow-x: hidden;

  @media screen and (max-width: $md-width) {
    margin-top: 8rem;
    padding: 1rem;
  }

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .title {
      display: flex;
    }

    .icon {
      width: 60px;
    }

    .text {
      font-size: 2rem;
      font-weight: 600;
      color: var(--text-color);
    }

    .button-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .btn {
        margin: 5px;
        font-weight: 700;
        color: var(--poolInfo-btn-text);
        padding: 8px 25px;
        border-radius: 30px;
        box-shadow: none;
      }

      .btn-add {
        border: 4px solid var(--poolinfo-btn-bg-outline);
        color: var(--poolinfo-btn-text-outline);
      }

      .btn-trade {
        background: var(--poolinfo-btn-bg);
      }
    }
  }

  .trading-stats {
    width: 100%;
    display: flex;
    // flex-wrap: wrap;

    @media screen and (max-width: $md-width) {
      flex-direction: column;
    }

    .stats {
      // flex-grow: 1;
      // flex-basis: 350px;
      width: 380px;
      display: flex;
      flex-direction: column;
      margin-right: 30px;

      @media screen and (max-width: $md-width) {
        width: 100%;
        margin: 10px 0;
      }


      .stat {
        display: flex;
        flex-direction: column;
        background: var(--poolinfo-stats-bg);
        color: var(--poolinfo-stats-text);
        padding: 10px 25px 50px 25px;
        border-radius: 30px;
        box-shadow: var(--poolinfo-token-shadow);

        .stats-block {
          padding: 10px;
          // display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .label-box {
            display: flex;
            flex-direction: column;
            font-weight: 700;
            margin: 0px 10px 10px 0px;
            .main-label {
              font-size: 14px;
              color: var(--poolinfo-stats-label);
            }

            .amount {
              font-size: 26px;
              font-weight: 700;
            }

            .percentage {
              font-size: 18px;
              font-weight: 400;
            }

            .img-stats-span{
              height: 15px;
              margin-bottom: 4px;
            }
          }
        }

        .locked-token-text {
          font-size: 14px;
          font-weight: 700;
          padding: 0 10px;
          margin-top: 50px;
        }

        .locked-tokens {
          background: var(--poolinfo-stats-locked-token-bg);
          border-radius: 30px;
          padding: 10px;
          margin-top: 10px;

          .token {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 10px;
            @media screen and (max-width: $md-width) {
              flex-direction: column;
              align-items: start;
            }
            a{
              text-decoration: none;
              // background: #e0e0e0;
              // padding: 5px 10px;
              // border-radius: 5px;
            }
            span {
              color: var(--poolinfo-stats-locked-token-text);
              font-weight: 600;
              cursor: pointer;
            }

            img {
              width: 40px;
              margin-right: 5px;
            }

            .token-data {
              display: flex;
              flex-direction: column;
              text-align: right;
              @media screen and (max-width: $md-width) {
                text-align: left;
                margin-top: 10px;
              }
            }

            &:not(&:last-child) {
              margin-bottom: 1rem;
            }
          }
        }
      }

      .stat-box {
        border: 1px solid var(--background-gradient-1);
        padding: 2rem;
        border-radius: 50px;
        background: var(--poolinfo-liquidity-bg);
      }

      .price {
        @extend .stat-box;

        .locked-token-text {
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .locked-token {
          border: 1px solid var(--background-gradient-1);
          padding: 1rem 1rem 1rem 1rem;
          border-radius: 50px;
          background: var(--poolinfo-locked-token-bg);
        }

        &__field {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 0.5rem;

          .label {
            flex-basis: 150px;
            font-weight: 550;
          }

          .value {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            font-size: 18px;

            .lqd {
              display: flex;
              flex-direction: column;
              text-align: right;
              font-weight: 700;

              .amount-usd {
                font-size: small;
              }
            }
          }
        }
      }
    }

    .chart {
      flex-grow: 1;
      // width: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: var(--poolinfo-token-shadow);
      padding: 1rem;
      border-radius: 20px;
      background: var(--poolinfo-liquidity-bg);

      @media screen and (max-width: $md-width) {
        padding: 0.5rem;
        width: 100%;
      }

      &-select {
        display: flex;
        justify-content: center;

        button {
          width: 100%;
          border: none;
          background: var(--poolinfo-chart-button-bg);
          color: var(--poolinfo-chart-button-text);
          border-top-left-radius: 10px;
          height: 50px;
        }

        button.active {
          background: var(--poolinfo-chart-button-active);
        }

        button:nth-child(2) {
          border-top-left-radius: 0px;
        }

        button:nth-child(3) {
          border-top-right-radius: 10px;
          border-top-left-radius: 0px;
        }
      }

      .chart-container {
        width: 100%;
        height: 500px;
        min-height: 500px;

        @media (max-width: 992px) {
          height: 400px;
          min-height: 400px;
        }

        .chart-trade-volume {
          width: 100%;
          height: 80%;

          @media (max-width: 992px) {
            height: 65%;
          }

          &-canvas {
            width: 100%;
            height: 100%;

            .chart-title {
              font-size: 16px;
              font-weight: 600;
            }

            .toolTip-wrapper {
              visibility: visible;
              transform: none;
              line-height: 10px;
              color: var(--text-accent-color);

              .date-tooltip {
                font-size: 18px;
                margin: 0;
              }

              .amt-tooltip {
                font-size: 18px;
                font-weight: 600;
              }
            }


            .tooltip-data {
              width: 100%;
            }
          }

          .spinner {
            display: flex;
            margin: auto;
            width: 200px;
          }


        }

        .chart-liqduidity {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 500px;
          @media (max-width: 992px) {
            height: 400px;
          }
          .spinner {
            margin: auto;
            width: 200px;
          }

          &-legend {
            display: flex;
            flex-direction: row;
            margin-bottom: 5rem;
            justify-content: flex-end;
            align-items: center;
            padding: 10px 0;
            @media screen and (max-width: $md-width) {
              flex-direction: column;
              align-items: end;
            }

            .switch, .switch1 {
              color: var(--filter-switch-chart);
              span {
                margin: 10px;
              }
              input[type="radio"]{
                margin: 0 20px 0 0;
              }
            }

            .current,
            .label {
              margin: 1rem;

              display: flex;
              flex-direction: column;
              font-size: large;
              font-weight: 600;
            }


          }

          &-canvas {
            width: 100%;
            height: 100%;
            // flex-grow: 1;
            // display: flex;
            // justify-content: flex-end;
            // flex-direction: row;

            .chart-title {
              font-size: 16px;
              font-weight: 600;
            }

          }

        }
      }



    }
  }

  .manage-pool-data{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: var(--poolManage-balance-bg);
    color: var(--manage-pool-text-color);
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-shadow: var(--poolinfo-token-shadow);
    @media screen and (max-width: $md-width) {
        padding: 40px;
        text-align: center;
    }

    @media screen and (max-width: $xxsm-width) {
      padding: 10px;
    }

    @media screen and (max-width: $md-width) {
    h4{
      font-size: 20px;
    }
    }
    button{
      background: var(--poolManage-connect-btn-bg);
      color: var(--poolManage-connect-btn-text);
      outline: none;
      border: 4px solid var(--poolManage-connect-btn-border);
      padding: 14px 40px;
      border-radius: 50px;
      font-size: 18px;
      font-weight: 600;
      box-shadow: -1px 1px 8px 2px #0000004d;
      @media screen and (max-width: $md-width) {
        font-size: 16px;
      }
    }

    .current-lp{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 20px;
      @media screen and (max-width: $xxsm-width) {
        margin: 20px 0;
      }
      span{
          font-size: 18px;
          font-weight: 600;
          text-transform: capitalize;
          margin-right: 5px;

          @media screen and (max-width: $xxsm-width) {
            font-size: 14px;
          }
      }

      input[type="number"]{
        width: 250px;
        height: 36px;
        border-radius: 20px;
        padding-left: 12px;
        border-color: var(--poolManage-lpbalance-input-border);
        color: var(--poolManage-lpbalance-input-text);
        
        @media screen and (max-width: $xxsm-width) {
          width: 180px;
        }

        &:focus-visible{
          border-color: var(--poolManage-lpbalance-input-border);
          outline: none;
        }

        &::-webkit-inner-spin-button,&::-webkit-outer-spin-button { 
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0; 
        }
      }
      svg{
        position: relative;
        margin: 0px -26px;
        color: var(--poolManage-lpbalance-input-text);
      }
    }

    .pair-balance{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90%;
      @media screen and (max-width: $md-width) {
        width: 100%;
      }
      .title{
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .skt-loader{
        width: 95%;
      }
      .pair{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: var(--poolManage-balance-token-bg);
        width: 95%;
        border-radius: 10px;
        margin-bottom: 20px;
        @media screen and (max-width: $md-width) {
          width: 100%;
          flex-direction: column;
        }
        svg{
          color: var(--poolManage-balance-token-text);
        }
        .first-pair,.second-pair{
          margin: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img{
            width: 30px;
          }
          span{
            color: var(--poolManage-balance-token-text);
            font-size: 18px;
            font-weight: 600;
            margin-left: 10px;
          }
        }
      }
    }
    .manage-group{
      margin-bottom: 12px;
    }
  }

  .tx-table {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;

    &__title {
      margin: 1rem;

      .filter{
        display: flex;
        align-items: center;
        margin-top: 12px;

        &-label{
          margin-right: 20px;
          label{
            display: flex;
            align-items: center;
            color: var(--pool-info-filter-text);
          }
          input[type="radio"] {
            width: 15px;
            height: 15px;

            &:checked:after {
              width: 15px;
              height: 15px;
              border-radius: 15px;
              top: -3.5px;
              left: 0px;
              position: relative;
              background-color: var(--pool-info-radio-color);
              content: '';
              display: inline-block;
              visibility: visible;
              border: 3px solid var(--pool-info-radio-bg-color);
          }
          }
    
          span{
            margin-left: 6px;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      .text {
        font-size: 1.5rem;
        font-weight: 550;
        text-transform: uppercase;
        color: var(--text-color);
      }

      .filter {
        overflow-x: auto;
      }
    }

    &__table {
      padding: 15px;
      overflow-x: auto;

      // margin: auto;
      table {
        border-radius: 20px;
        box-shadow: var(--poolinfo-table-shadow);
        width: 100%;
        overflow: hidden;

        th,
        td {
          padding: 20px 30px;
          a{
            color: var(--link-color-main);
          }
        }

        tr {
          border-bottom: var(--poolinfo-table-border);
        }

        thead {
          background: var(--analytic-table-head);
          tr {
            // border-bottom: 1px solid var(--background-gradient-1);

            th {
              font-weight: 550;
              text-transform: uppercase;
              color: var(--poolinfo-table-text);
            }
          }
        }

        tbody {
          background-color: var(--poolinfo-table-bg-body);

          tr:nth-child(even) {
            background-color: var(--poolinfo-table-stripped);
          }

          tr{
            color: var(--poolinfo-table-body-text);
          }
        }
      }

      &__pagination {
        display: flex;
        justify-content: center;
        padding: 20px;

        .pg-button {
          // border-radius: 50%;
          // width: 45px;
          // height: 45px;
          border: none;
          background: transparent;
          // background: var(--poolinfo-pagination-bg);
          color: var(--poolinfo-pagination-text);
          margin: 4px;

        }
        .active{
          color:var(--text-accent-color);
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
  }
}

.toolTip-wrapper{
  color: var(--token-info-tooltip-text);
  .total{
    font-size: 18px;
    margin: 0;
    line-height: 20px;

    &:nth-child(2){
      line-height: 20px;
    }
  }

  .totalusd{
      font-size: 24px;
      font-weight: 700;
      margin: 0;
      line-height: 30px;
  }

  .date-tooltip{
      font-size: 16px;
      margin: 0;
  }
}

@media (max-width: 991px) {
  .pool-info {
    .tx-table {
      &__table {
        margin: 0;
      }
    }
  }
}