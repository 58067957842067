.chartLoading {
  margin: auto;
  height: 130px;
  width: 160px;
  padding: 10px 20px;
}

@keyframes chartLoading {
  0% {
    background-color: var(--chart-loaderbg-1);
  }

  30% {
    background-color: var(--chart-loaderbg-2);
  }

  50% {
    height: 50px;
    margin-top: 55px;
  }

  80% {
    background-color: var(--chart-loaderbg-3);
  }

  100% {
    background-color: var(--chart-loaderbg-1);
  }
}

// @-moz-keyframes chartLoading {
//   50% { height: 50px; margin-top: 56px; }
// }
// @-o-keyframes chartLoading {
//   50% { height: 50px; margin-top: 56px; }
// }
// @keyframes chartLoading {
//   50% { height: 50px; margin-top: 56px; }
// }
@mixin inner() {
  height: 10px;
  width: 30px;
  background-color: var(--chart-loaderbg-1);
  display: inline-block;
  margin-top: 100px;
  -webkit-animation: chartLoading .6s infinite;
  -moz-animation: chartLoading .6s infinite;
  -o-animation: chartLoading .6s infinite;
  animation: chartLoading .6s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

}

@mixin chartLoading() {
  @for $i from 1 through 10 {
    .chartLoading-#{$i} {
      @include inner();
      -webkit-animation-delay: $i/4+s;
      animation-delay: $i/4+s;
    }
  }
}

.chartLoading {

  @include chartLoading();
}

.load {
  display: flex;
  justify-content: center;
  color: var(--text-accent-color);

  .chartdot {
    margin: 0px 0px 0px 10px;
    color: var(--tv-text);
    width: 30px;
    height: 30px;
    --d: radial-gradient(farthest-side, currentColor 90%, #0000);
    background: var(--d), var(--d), var(--d);
    background-size: 4px 4px;
    background-repeat: no-repeat;
    animation: m 1s infinite alternate;
  }

  @keyframes m {
    0% {
      background-position: calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 50%
    }

    20% {
      background-position: calc(0*100%/2) 0, calc(1*100%/2) 50%, calc(2*100%/2) 50%
    }

    40% {
      background-position: calc(0*100%/2) 100%, calc(1*100%/2) 0, calc(2*100%/2) 50%
    }

    60% {
      background-position: calc(0*100%/2) 50%, calc(1*100%/2) 100%, calc(2*100%/2) 0
    }

    80% {
      background-position: calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 100%
    }

    100% {
      background-position: calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 50%
    }
  }
}