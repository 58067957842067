.footer {
  display: flex;
  justify-content: center;

  .navbar {
    width: fit-content;
    justify-content: center;
    margin: 5px;
  }
}
