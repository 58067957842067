.faq-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 120px 40px 120px;

  @media (max-width: $lg-width) {
    padding: 120px 20px 20px 20px;
  }

  .main-faq-title {
    font-size: 32px;
    font-weight: 600;
    color: var(--faq-main-text);
  }

  .section-wrapper {
    padding: 1rem;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
      color: var(--faq-main-text);
      margin-bottom: 1rem;

      .section-b {
        display: flex;
        margin-top: 10px;
        .clipboard {
          display: none;

          .copy-clipboard {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      &:hover {
        .section-b {
          .clipboard {
            display: flex;
            align-items: center;
            justify-content: center;

            .clip-pop {
              position: absolute;
              background: #a4124d40;
              padding: 1px 10px;
              font-size: 14px;
              margin-top: -48px;
              margin-left: 30px;
              border-radius: 10px;
              @media (max-width: $xxsm-width) {
                margin-top: -30px;
                margin-left: 19px;
              }
            }
          }
        }
      }
    }

    &-body {
      .section-one {
        // border-radius: 5px;
        padding: 20px;
        // box-shadow: var(--faq-box-shadow);
        background: var(--faq-section-bg);

        .section-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 14px;
          text-transform: uppercase;
          color: var(--faq-title-text);

          .section-b {
            display: flex;
            margin-top: 10px;
            .clipboard {
              display: none;

              .copy-clipboard {
                margin-left: 10px;
                cursor: pointer;
              }
            }
          }

          &:hover {
            .section-b {
              .clipboard {
                display: flex;
                align-items: center;
                justify-content: center;

                .clip-pop {
                  position: absolute;
                  background: #a4124d40;
                  padding: 1px 10px;
                  font-size: 14px;
                  margin-top: -48px;
                  margin-left: 30px;
                  border-radius: 10px;
                  @media (max-width: $xxsm-width) {
                    margin-top: -30px;
                    margin-left: 19px;
                  }
                }
              }
            }
          }
        }

        svg {
          cursor: pointer;
        }
      }

      ul {
        list-style: none;

        @media (max-width: $lg-width) {
          padding: 5px;
        }

        li {
          .list-style {
            margin-left: 10px;
            word-wrap: break-word;
          }

          margin-bottom: 10px;
          color: var(--faq-list-text);
          font-weight: 300;

          svg {
            margin-right: 6px;
            margin-top: 4px;
            color: var(--faq-icon-bg);
          }

          .inner-list {
            @media (max-width: $lg-width) {
              padding: 5px 20px;
            }

            li {
              .list-style {
                margin-left: 10px;
                word-wrap: break-word;
              }

              margin-bottom: 0;
            }

            svg {
              margin-top: 4px;
              font-size: 12px;
            }
          }
        }
      }

      .highlight {
        color: var(--faq-main-text);
        font-weight: 500;
      }

      .link {
        color: var(--faq-link);
        font-weight: 500;
      }
    }
  }

  .faq-content-section {}
}