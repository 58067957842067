.token-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8rem 0rem;
    padding: 10px 120px;

    @media (max-width: $md-width) {
        padding: 10px;
        margin: 8rem 0rem;
    }

    .search-token {
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        padding: 10px 16px;
        justify-content: right;

        .icon {
            position: relative;
            margin: 14px -30px;
            color: var(--pool-search-icon-input-color);
        }

        @media (max-width: $sm-width) {
            justify-content: center;
        }

        .search-field {
            @extend .no-outline;
            border: none;
            width: 30%;
            padding: 10px;
            padding-left: 40px;
            border-radius: 20px;

            @media (max-width: $lg-width) {
                width: 60%;
            }

            @media (max-width: $sm-width) {
                width: 90%;

            }

            &:focus-visible {
                outline: none;
            }
        }
    }

    .token-data-container {
        .token-titles {
            display: grid;
            grid-template-columns: 200px 150px 150px auto;
            justify-content: space-around;
            padding: 10px;
            width: 90%;
            color: var(--bar-chart-text-heading);

            @media (max-width: $lg-width) {
                grid-template-columns: 80px 80px 80px auto;
                width: 70%;
            }

            @media (max-width: $md-width) {
                grid-template-columns: auto auto auto auto;
                width: 100%;
            }
        }

        .token-data {
            display: flex;
            width: 100%;
            align-items: center;
            flex-flow: column;

            .no-data {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: var(--token-header-bg);
                height: 80px;
                border-radius: 10px;
            }

            .token-header {
                display: grid;
                grid-template-columns: 200px 150px 150px auto;
                flex-wrap: wrap;
                width: 100%;
                flex-direction: row;
                justify-content: space-around;
                background: var(--token-header-bg);
                align-items: center;
                height: 80px;
                margin-bottom: 10px;
                border-radius: 10px;
                transition: 0.3s;
                color: var(--token-header-text);
                @media (max-width: $md-width) {
                    grid-template-columns: none;
                    grid-template-rows: auto auto auto auto;

                }

                @media (max-width: $lg-width) {
                    display: flex;
                    grid-template-columns: none;
                }

                @media (max-width: $xxsm-width) {
                    font-size: 14px;
                }

                .tk-image{
                    img{width: 30px;}
                }

                .tk-price {
                    text-align: left;
                }

                .coin {
                    img {
                        width: 30px;

                        @media (max-width: $xxsm-width) {
                            width: 20px;
                        }
                    }
                }

                &:hover {
                    background: var(--tokenPrice-hover);
                    color: var(--tokenPrice-text-hover);
                    width: 100%;
                    color: var(--token-header-hvr-text);
                    .btn {
                        background: var(--token-btn);
                        color: var(--token-btn-text);
                    }

                    a{
                        color: var(--link-color-main-hvr);
                    }

                }

                .btn {
                    width: 150px;
                    background: var(--token-btn-hvr);
                    color: var(--token-btn-hvr-text);

                    @media (max-width: $md-width) {
                        width: auto;
                    }
                }
            }


            .token-header-card{
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                background: var(--token-header-bg);
                color: var(--token-header-text);
                border-radius: 10px;
                margin-bottom: 10px;

                .data{
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    border-bottom: 2px solid var(--token-bottom-color);
                    padding: 15px 20px;
                    &:last-child{
                        border-bottom:0;
                    }
                    .label{
                        font-weight: 500;
                        margin-bottom: 5px;

                    }
                    .img-data{
                        img{
                            width: 40px;
                        }
                    }
                }
                .btn {
                    margin: 20px 0;
                    width: 60%;
                    background: var(--token-btn-hvr);
                    color: var(--token-btn-hvr-text);
                }
            }

            .token-content {
                display: none;
                background: var(--token-content-bg);
                width: 90%;
                border-radius: 10px;
                margin-bottom: 20px;

                .tx-table__table {
                    height: 200px;
                    overflow: auto;

                    table {
                        overflow: hidden;

                        thead {
                            background: var(--tokenPrice-hover);
                            color: var(--tokenPrice-text-hover);
                        }

                        tbody {
                            tr {

                                &:nth-child(even) {
                                    background: var(--analytic-table-even-td);
                                }
                            }
                        }
                    }

                }
            }

            .active {
                display: block;
                overflow: hidden;
            }
        }

        // .token-data-card {
        //     display: flex;
        //     flex-wrap: wrap;
        //     justify-content: center;
        //     .token-header {
        //         display: flex;
        //         flex-direction: column;
        //         justify-content: center;
        //         align-items: flex-start;
        //         width: 250px;
        //         background: var(--token-header-bg);
        //         color: var(--token-header-text);
        //         // padding: 30px 30px 20px 30px;
        //         border-radius: 20px;
        //         margin: 10px;
        //         .tk-image{
        //             font-weight: 500;
        //             img{
        //                 width: 50px;
        //             }
        //         }
        //         .tk-address{
        //             display: flex;
        //             span{
        //                 margin-right: 3px;
        //             }
        //         }
        //         .tk-button{
        //             margin-top: 10px;
        //             width: 100%;
        //             .btn {
        //                 width: 100%;
        //                 background: var(--token-btn-hvr);
        //                 color: var(--token-btn-hvr-text);
        //             }
        //         }
        //     }
        // }
    }

    .pagination {
        display: flex;
        justify-content: center;
        padding: 20px;

        .pg-button {
            border: none;
            background: transparent;
            color: var(--poolinfo-pagination-text);
            margin: 4px;
        }

        .active {
            color: var(--text-accent-color);
            font-weight: 600;
            font-size: 18px;
        }
    }

}