// COLORS
$color-new-blue: #007bff;
$color-new-green: #25ac37;
$color-new-red: #ff0000;
$color-new-orange: #ff8c00;
$color-new-gray : #929292;

$color-new-green-light: lighten(#25ac37, 30%);
$color-new-red-light: lighten(#ff0000, 30%);

$color-light-blue: #70d6ff;
$color-very-light-blue: #f8f9fa;

//Landing Colors
$color-text-light-blue: #0db2d7;
$color-text-yellow: #ffc512;
$color-hr-tag: 1px solid #27225c;
$bg-dex-counter: #191b74;
$dex-text-color: #9e9fe3;
$bg-dex-counter-border: 1px solid #4649ca;

$bg-button-color: linear-gradient(90deg, #0fa9d2, #2a2e97);
$bg-button-hover-color: linear-gradient(90deg, #2a2e97, #0fa9d2);

// BREAK POINTS
$xlg-width: 1460px;
$xlg-width3: 1280px;
$xlg-width2: 1024px;
$lg-width: 992px;
$md-width: 762px;
$sm-width: 580px;
$xxsm-width:380px;

