.header-button {
  @extend .no-select;
  @extend .no-outline;

  text-transform: uppercase;
  padding: 5px 10px 5px 10px;
  background-color: var(--header-button-background);
  border: 1px solid transparent;
  color: var(--header-button-color);
  border-radius: 5px;
  margin: 5px;
  height: 40px;

  &:disabled,
  &:hover,
  &:active {
    background-color: var(--header-button-background--hover) !important;
    border: 1px solid var(--header-button-border-color) !important;

    color: var(--header-button-color) !important;
  }
}

.xdc-connect-btn {
  @extend .header-button;
}
