.token-info-wrapper {
    margin-top: 5rem;
    width: 100%;
    padding: 4rem;
    @media screen and (max-width: $sm-width) {
        padding: 1rem;
        margin-top: 4rem;
    }
    .token-container {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 2em;

        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }

        .token {
            &-stats {
                flex-basis: 300px;
                display: flex;
                flex-direction: column;
                background: var(--poolinfo-stats-bg);
                border-radius: 24px;
                color: var(--token-info-stats-text1);
                padding: 1px 1px 3px;
                @media screen and (max-width: $md-width) {
                    flex-basis: 100%;
                    margin: 20px 0;
                }
            }

            &-data {
                padding: 20px 20px 10px 20px;

                &:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6){
                    padding: 10px 20px;
                }
              
                img{
                    width: 50px;
                }
            }

            &-label {
                color: var(--token-info-stats-text);
                font-weight: 600;
                line-height: 1.5;
                text-transform: uppercase;
                font-size: 12px;

            }

            &-amt {
                color: var(--token-info-stats-text1);
                font-weight: 600;
                line-height: 1.5;
                font-size: 18px;

                &-usd{
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            &-percentage {
                color: rgb(237, 75, 158);
                font-size: 16px;
                font-weight: 400;
                line-height: 1.5;

            }

        }

        .chart {
            &-stats {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                padding: 1rem;
                border-radius: 20px;
                background: var(--poolinfo-stats-bg);
                border-radius: 24px;
                color: var(--token-info-stats-text1);
                padding: 1px 1px 3px;
            }

            &-button{
                display: flex;
                justify-content: center;
                button{
                    width: 100%;
                    border-top-left-radius: 20px;
                    &:nth-child(2){
                        border-top-left-radius: 0px;
                    }
                    &:last-child{
                        border-top-left-radius: 0px;
                        border-top-right-radius: 20px;
                    }
                }
                .ct-btn{
                    padding: 0.9rem 0.75rem;
                    font-size: 1rem;
                    color: var(--token-info-tab-button-text);
                    border: none;
                }

                .active-btn{
                    color: var(--token-info-tab-button-active-text);
                    background: var(--token-info-tab-button-active);
                }
            }
        }
        .charts{
            width: 100%;
            height: 100%;
            @media screen and (max-width: 800px) {
                height: 400px;
            }

            .chart-container{
                width: 100%;
                height: 380px;
                overflow: hidden;
            }
            .toolTip-wrapper{
                color: var(--token-info-tooltip-text);
                .total{
                    font-size: 16px;
                    margin: 0;
                }

                .totalusd{
                    font-size: 24px;
                    font-weight: 700;
                    margin: 0;
                    line-height: 16px;
                }

                .date-tooltip{
                    font-size: 18px;
                    margin: 0;
                }
            }

            .chart-liqduidity-legend{
                margin: 10px 0px 0px 30px;
                display: flex;
                .switch,.switch1{
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    span{
                        margin-right: 8px;
                    }
                }
            }


        }
    }

    .tokenTable{
        padding: 30px;
    }

    .table-container{
        h2{
            text-transform: uppercase;
            font-size: 20px;
            padding: 10px 0;
            font-weight: 600;
            margin-top: 20px;
            color: var(--bar-chart-text-heading);
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
}