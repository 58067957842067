.react-bootstrap-table-pagination div:first-child{
	display: none;
}
.react-bootstrap-table-pagination{
	padding: 0px 40px !important;
}
.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list{
	flex: 0 0 100%;
    max-width: 100%;
}
.react-bootstrap-table-page-btns-ul{
	display: flex;
    justify-content: center;
	float: none;
	margin-bottom: 0rem;
}
.react-bootstrap-table-page-btns-ul .page-item{
	 font-size: 16px;
}
.react-bootstrap-table-page-btns-ul .page-item .page-link{
	border: none;
    background: transparent;
    color: var(--poolinfo-pagination-text);
    margin: 4px;
   
}
.react-bootstrap-table-page-btns-ul li.active{
	color: var(--text-accent-color);
    font-weight: 600;
    font-size: 18px;
    margin: -2px;
}
.react-bootstrap-table-page-btns-ul li:hover{
    font-weight: 500;
    font-size: 17px;
    margin: -1px;
}

.page-link:focus,
.page-link:focus-visible {
    box-shadow: none;
}
.page-link{
	padding: 0.5rem 0.35rem;
}


