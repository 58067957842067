.tokenPrice-row-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8rem 0rem;
    padding: 10px 120px;

    @media (max-width: $lg-width) {
        padding: 20px;
    }

    p {
        color: var(--bar-chart-text-heading);
    }

    h3 {
        color: var(--bar-chart-text-heading);
        text-transform: uppercase;
        font-size: 20px;
        padding: 0 20px;
        font-weight: 600;
        text-align: left;
    }

    .titles {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 10px;
        width: 98%;
        color: var(--bar-chart-text-heading);
    }

    .token-data {
        display: flex;
        width: 100%;
        align-items: center;
        flex-flow: column;

        .no-data {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: var(--token-header-bg);
            height: 80px;
            border-radius: 10px;
        }

        .token-header {
            display: grid;
            grid-template-columns: 200px 200px 200px auto;
            flex-wrap: wrap;
            width: 98%;
            flex-direction: row;
            justify-content: space-around;
            background: var(--token-header-bg);
            align-items: center;
            height: 80px;
            margin-bottom: 10px;
            border-radius: 10px;
            transition: 0.3s;
            color: var(--token-header-text);
            @media (max-width: $lg-width) {
                display: flex;
                grid-template-columns: none;
            }

            .icon {
                cursor: pointer;
            }

            .coin {
                img {
                    width: 50px;
                }
            }

            &:hover {
                color: var(--token-header-hvr-text);
                background: var(--tokenPrice-hover);
                color: var(--tokenPrice-text-hover);
                width: 100%;
            }
        }

        .token-content {
            display: none;
            background: var(--token-content-bg);
            width: 90%;
            border-radius: 10px;
            margin-bottom: 20px;

            .tx-table__table {
                height: 200px;
                overflow: auto;

                table {
                    overflow: hidden;
                    margin-bottom: 0;
                    thead {
                        background: var(--tokenPrice-hover);
                        color: var(--tokenPrice-text-hover);
                    }

                    tbody {
                        tr {
                            color: var(--analytic-table-text);
                            &:nth-child(even) {
                                background: var(--analytic-table-even-td);
                                
                            }
                        }
                    }
                }

            }
        }

        .active {
            display: block;
            overflow: hidden;
        }
    }

    .token-data-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .card-wrapper {
            display: flex;
            flex-direction: column;
            
            .token-header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                background: #FFF;
                padding: 30px 30px 20px 30px;
                border-radius: 20px;
                margin: 10px;

                .img-block {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                }

            }

            .token-content-card {
                display: none;
                width: 250px;
                margin: 0px 14px;

            }

            .active {
                
                display: block;
                overflow: hidden;
            }
        }


    }

    .search-token {
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        padding: 10px 16px;
        justify-content: right;

        .icon {
            position: relative;
            margin: 14px -30px;
            color: var(--pool-search-icon-input-color);
        }

        .search-field {
            @extend .no-outline;
            border: none;
            width: 30%;
            padding: 10px;
            padding-left: 40px;
            border-radius: 20px;

            @media (max-width: $lg-width) {
                width: 60%;
            }

            @media (max-width: $sm-width) {
                width: 100%;

            }

            &:focus-visible {
                outline: none;
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        padding: 20px;

        .pg-button {
            border: none;
            background: transparent;
            color: var(--poolinfo-pagination-text);
            margin: 4px;
        }

        .active {
            color: var(--text-accent-color);
            font-weight: 600;
            font-size: 18px;
        }
    }
}