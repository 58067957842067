footer {
  flex-shrink: 0;
  background: var(--body-bg-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // position: relative;
  top: 80px;
  padding: 20px 180px;
  z-index: 10;
  width: 100%;
}

.mb-30 {
  @media (max-width: $lg-width) {
    margin-bottom: 3rem;
  }
}

.footer-section {
  color: var(--footer-copyright-text);

  .copyright-content {
    font-size: 0.8em;
  }

  .social-icons {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: var(--footer-social-icons-border);
    text-align: center;
    margin: 0 2px;
    cursor: pointer;
  }

  .social-links {
    display: flex;

    .social-icons{
      a{
        color: var(--footer-social-icons-border);
      }
      i {
        padding: 10px;
      }
    }
  }
}

@media (max-width: 991px) {
  footer {
    padding: 20px;
    flex-direction: column;
  }

  .footer-section {
    .copyright-content {
      margin-bottom: 16px;
    }

    .social-links {
      .social-icons {
        width: 30px;
        height: 30px;
        border: --footer-social-icons-border-res;
        margin: 0px 2px;

        i {
          padding: 5px 0px;
        }
      }
    }
  }
}
