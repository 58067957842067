.__iri {
  --background-gradient-1: #e55d87;
  --background-gradient-2: #5fc3e4;

  --header-button-background: pink;
  --header-button-background--hover: darken(pink, 10%);
  --header-button-color: black;
  --header-button-border-color: darken(pink, 50%);

  --text-primary-color: #9c3e5c;
  --text-accent-color: #f85797;
  --text-secondary-color: #a4124d;

  --background-primary: #9c3e5c;
  --background-accent: #f85797;
  --background-secondary: #a4124d;

  //landing bg
  --body-bg-color: #fff3f8;
  --header-bg-color: white;
  --header-box-shadow: 0px 5px 18px -8px rgba(0, 0, 0, 0.158);
  --text-color: #f85797;
  --header-button-bg: linear-gradient(90deg, #a4124d, #f37aaa);
  --header-button-text: #f85797;
  --header-button-bg-hover: #f1669e;
  --header-xdc-button: #fff1f8;
  --header-xdc-button-text: #a4124d;

  //landing Page colors
  --landing-content-margin-bt: 0;
  --landing-dex-text-h1: #fe036a;
  --landing-dex-text-h3: #b01e73;
  --landing-dex-text-align: left;
  --landing-dex-text-weight: 600;
  --color-text-light-blue: #f1669e;
  --color-text-yellow: #f1669e;

  //Header Colors
  --header-dropdown-bg: #ffffff;
  --header-dropdown-bg-hover: #f1669e;
  --header-dropdown-text-color: #ffffff;

  //Mobile Nav bg
  --bg-mob-nav-hover: #9c3e5c;
  --bg-mob-nav-hover-text: #f85797;
  --bg-nav-burger: #f85797;
  --bg-nav-burger-shadow: 0 10px 0 #f85797;

  //landing page layout
  --body-text: #9c3e5c;
  --main-hero-text: center;
  --main-hero-text-align: center;
  --lptoken-text-align: left;
  --swap-token-padding: 80px 80px;
  --swap-content-margin: 0;

  //large device layout
  --main-hero-text-large: space-evenly;
  
  //Swap Button pill colors
  --bg-pill-button: #ffffff;
  --pill-button-text: #9c3e5c;
  --swap-button-bg: #f85797;
  --token-box-bg: #ffffff;
  --token-box-border: 1px solid #ead6e0;
  --token-box-border-focus: 1px solid #f85797;
  --input-text-bg: #ffffff;

  //Swap text
  --title-text-color: #9c3e5c;
  --subTitle-text-color: #9b7081;
  --swap-text-input: #9c3e5c;

  // swap icons color
  --swap-icon-color: #f85797;

  //swap-modal
  --modal-border: #f85797;
  --modal-bg-color: #fff1f8;
  --modal-border-color: 1px solid #f85797;
  --modal-list-hover: #d364855d;
  --modal-box-shadow: 0px 2px 4px rgba(153, 47, 139, 0.3);

  //pool
  --pool-search-placeholder: #f85797;
  --pool-card-btn-bg-pair: #f85797;
  --pool-card-btn-bg-info: #f85797;
  --pool-action-bg: #f6e4ed;
  --pool-search-icon-input-color: #a79399;

  //pool info
  --poolinfo-liquidity-bg: transparent;
  --poolinfo-locked-token-bg: #bd6e8c42;
  --poolinfo-pagination-bg: #f85797;
  --poolinfo-pagination-text: #f85797;
  --poolinfo-chart-button-bg: #a79399;
  --poolinfo-chart-button-text: #FFF;

  --poolinfo-chart-button-active: #9c3e5c;
  --poolinfo-table-bg-body: #fff;
  --poolinfo-table-text: #fff;
  --poolinfo-table-body-text:#000;
  --poolinfo-table-border: #929292;
  --poolinfo-table-shadow: 0px 0px 6px 3px rgba(153, 70, 135, 0.5);
  --poolinfo-table-stripped: #f1f1f18a;
  --poolinfo-token-shadow: rgba(231, 46, 216, 0.603) 0px 1px 1px,
    rgba(180, 29, 173, 0.753) 0px 1px 1px,
    rgba(128, 35, 128, 0.466) 0px 0px 0px 1px inset;
  --poolinfo-stats-bg: #fff;
  --poolinfo-stats-text: #000;
  --poolinfo-stats-label: #9c3e5c;
  --poolinfo-stats-locked-token-bg: #a793991a;
  --poolinfo-stats-locked-token-text: #000;
  --poolinfo-btn-bg-outline: #f85797;
  --poolinfo-btn-bg: #f85797;
  --poolinfo-btn-text-outline: #f85797;
  --pool-card-bg: #fff;
  --pool-card-title-text: #000;
  --pool-card-remove:#000;


  //tokenlist
  --addToken-bg: #ff98c08f;
  //footer
  --footer-bg: #641f3a;
  --footer-border: #6c2641;
  --footer-copyright-text: #f85797;
  --footer-social-icons-border: 3px solid #f85797;
  --footer-social-icons-border-res: 2px solid #f85797;

  //header image
  --call-action-bg-img: url("../../dex/iri/gebx-coins.jpg");

  //modal slippage
  --modal--slippage-field-label: #04051d;
  --modal-slippage-input: #9c3e5c;
  --modal-slippage-button-bg: #f6e4ed;
  --modal-slippage-button-text: #f85797;

  //analytics
  --bar-chart-day-text: #f85797;
  --bar-chart-day-text-active: #fff;
  --bar-chart-text-heading: #a4124d;
  --table-text-color: #fff;
  --table-border-td-bg-color: #f0abc6ef;
  --table-border-color: #fff;
  --analytic-table-head: #f85797;

  //tvl
  --tvl-bg: #e48caf54;
  --tv-text: #f85797;

  //toggle
  --toggle-bg: #FFF;
  --toggle-text: #f85797;

  --tokenPrice-hover: #eb70a1;
  --tokenPrice-text-hover: #fff;

  --learnMore-bg: #f6d9e5;
  --learn-text: #000;

  --faq-main-text: #a4124d;
  --faq-title-text: #f85797;
  --faq-list-text: #90586e;
  --faq-icon-bg: #f85797;
  --faq-box-shadow: rgba(142, 18, 106, 0.11) 0px 0px 4px 0px inset,
    rgba(218, 218, 218, 0.25) 0px 50px 100px -20px,
    rgba(249, 114, 160, 0.35) 0px 30px 60px -30px;
    --faq-section-bg:#FFF;
    --faq-link:#3737ff;

  --toast-progress-color: #a4124d;
  --input-placeholder-text: gray;

  --chart-loaderbg-1: #a4124c5e;
  --chart-loaderbg-2: #a4124c6e;
  --chart-loaderbg-3: #a4124c5e;

  --custom-skeleton--highlight: #fdb3d231;
  --custom-skeleton--bg: #ebebeb;
  --custom-skeleton--bh: #ebebeb;
  --base-color-bg:#ebebeb;

  --coinPair-text:#FFF;
  --btn-text:#FFF;
  --landing-gb-bg:#ffecf4;
  --text-iri-dark:#641f3a;
  --text-iri-light:#f85797;
  --supply-boder: #ffc5db;
  --supply-img-bg:#f85797;
  --btn-dex-iri-solid-bg:#f85797;
  --btn-dex-iri-outline-text:#f85797;
  --btn-dex-iri-solid-white-bg:#FFF;
  --btn-dex-iri-solid-white-text:#FFF;

  --notifiy-info-bg:#ffffff;
  --notifiy-info-text:#808080;
  --notifiy-success-bg:#fff;
  --notifiy-success-text:#808080;
  --notifiy-warning-bg:#fff;
  --notifiy-warning-text:#808080;
  --notifiy-processing-bg:#fff;
  --notifiy-processing-text:#808080;
  --notifiy-error-bg:#fff;
  --notifiy-error-text:#808080;

  --pool-action-btn-bg:#c7c1c1;
  --pool-action-btn-text:#000;
  --pool-action-btn-a-bg:#ffffff;
  --pool-action-btn-a-border:#d3d3d3;

  --btn-danger-bg:#ec4134;
  --btn-danger-text:#ffff;


  --modal-setting-bg:#FFF;
  --modal-ack-bg:#FFF;
  --modal-ack-text:#000;

  --modal-setting-popover-text:#FFF;
  --modal-setting-popover-bg:#000000d9;
  --modal-setting-bg-input:#fff;

  --analytic-table-bg:#fff;
  --analytic-table-even-td:#f1f1f18a;
  --analytic-table-pagination-text:#fff;

  --token-header-bg: #FFF;
  --token-header-text: #000;
  --token-header-hvr-text: #FFF;
  --token-content-bg:#FFF;
  
  --token-load-border:#d3d3d3;
  --token-load-body-name-text:#808080;
  --token-load-body-name-hover:#d3d3d3;

  --token-list-custom-action-text:#FFF;
  --token-list-custom-list-border:#d3d3d3;
  --token-list-custom-list-body-name-text:#808080;
  --token-list-custom-list-add-text:#ffffff;
  --token-list-custom-list-no-token-list:#ffffff;
  --token-list-custom-list-active-bg:#f5f5f5;
  
  --swap-bg:#FFF;
  --swap-input-placeholder:#808080;
  --swap-subtext:#808080;
  --swap-inversrate-text:#808080;
  --swap-route-text:#fff;
  --swap-route-path-bg:#000000d9;
  --swap-route-path-text:#fff;
  --swap-route-vtext-text:#4241417d;
  --swap-route-coinchain-bg:#645e60;
  --swap-route-coinchain-text:#FFF;
  --swap-route-dot:#FFF;
  --swap-submit-text:#FFF;
  --swap-submit-border:#d3d3d3;
  --swap-field-bg:#f5f5f5;
  --swap-field-border:#d3d3d3;

  --poolcard-content-bg:#f0f8ff;
  --poolcard-content-value-text:#000;
  --poolcard-content-remove:#ff0000;
  --poolcard-content-btn-text:#FFF;

  --poolrow-bg:#ffffff;
  --poolrow-content-text:#000;
  --poolrow-border: #d3d3d3;
  --poolrow-pair-btn-text:#ffffff;
  --poolrow-remove-btn:#ff0000;

  --poolInfo-btn-text: #FFF;
  --poolInfo-amt-usd-text: #04051d;

  --pool-active-btn: #FFF;
  --display-view-btn-bg: #fff;
  --display-view-btn-text: #000 ;
  --display-view-active-btn: #f85797;

  --pool-info-radio-bg-color:#a4124d;
  --pool-info-radio-color:#f85797;
  --pool-info-filter-text:#f85797;
  --poolinfo-btn-text-outline-dark : #f85797;
  --poolManage-balance-bg:#FFF;
  --poolManage-balance-token-bg:#a4124d;
  --poolManage-balance-token-text:#FFF;
  --poolManage-lpbalance-input-text:#f85797;
  --poolManage-lpbalance-input-border:#f85797;
  --poolManage-connect-btn-bg:#a5144f;
  --poolManage-connect-btn-text:#FFF;
  --poolManage-connect-btn-border:#0000;
  
  --token-info-tooltip-text:#f85797;
  --token-info-tab-button-text:#000;
  --token-info-tab-button-active-text:#f85797;
  --token-info-stats-text:#f85797;

  --clipboard-bg:#a4124d40;
  --clipboard-text:#f85797;

  --token-btn:#FFF;
  --token-btn-text:#f85797;
  --token-btn-hvr:#f85797;
  --token-btn-hvr-text:#FFF;

  --notifi-bg:#f8579777;
  --notifi-text:#FFF;
  
  --error-page-bt-text:#FFF;
  --error-page-bt-bg:#f85797;
  --error-page-bt-shadow: 0px 0px 6px 3px #f857976e;

  --filter-switch-chart: #000;
  --analytic-table-text:#000;
  --token-info-stats-text1:#280d5f;
  --token-info-tab-button-active:#FFF;
  --manage-pool-text-color: #000;

  --link-color-main: #1111d3;
  --link-color-main-hvr: #1000ff;

  --token-bottom-color:#c0c0c0;

  --walletStatus-bg: #a4124d;
  --walletStatus-account-bg: #fff3f8;
  --walletStatus-text: #fff;
  --walletStatus-text-acc: #f85797;
  --walletStatus-footer-bg:#fadce9;
  --walletStatus-theme:#f85797;

  --maintenance-text:#a4124d;
}
