.table-container {
  margin-top: 40px;

  .table {
    .react-bootstrap-table {
      margin-bottom: 10px;


      @media (max-width: $lg-width) {
        overflow-x: auto;
        padding: 20px;
      }


      .table {
        color: var(--analytic-table-text);
        border: none;
        table-layout: auto;
        background: var(--analytic-table-bg);
        border-radius: 10px;
        overflow: hidden;
        text-transform: uppercase;

        thead {
          @extend .no-select;
          @extend .no-outline;
          // border-bottom: 2px solid var(--table-border-color);
          background: var(--analytic-table-head);
          color: var(--table-text-color);

          th {

            @extend .no-select;
            @extend .no-outline;

            font-weight: 400;
            text-align: right;
            padding: 20px 20px;

            // border-right: 1px solid;
            &:nth-child(1) {
              text-align: left;
            }
          }

          .caret {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 2px;
            vertical-align: middle;
            border-top: 4px dashed var(--table-border-color);
            border-top: 4px solid \9;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
          }

          .dropup .caret,
          table thead .navbar-fixed-bottom .dropdown .caret {
            border-top: 0;
            border-bottom: 4px dashed var(--table-border-color);
            ;
            border-bottom: 4px solid \9;
            content: "";
          }

        }

        tbody {
          tr {
            border-bottom: 1px solid var(--table-border-td-bg-color);
            font-weight: 600;
            color: var(--analytic-table-text);
            &:last-child {
              border-bottom: none;
            }

            td {
              padding: 20px 18px;
              .img-group {
                text-transform: uppercase;

                a {
                  text-decoration: none;
                  color: var(--analytic-table-text);

                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                  }

                  .img2 {
                    margin-left: -6px;
                  }
                }
              }

              text-align: right;

              &:nth-child(1) {
                text-align: left;
              }
            }

            &:nth-child(even) {
              background: var(--analytic-table-even-td);
            }
          }
        }
      }

      .table td {
        border: none;
      }

      .table th {
        border-top: none;
      }

      .table-bordered th,
      .table-bordered td {
        border: none;
      }
    }
  }
}

.tx-table {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;

  &__title {
    margin: 1rem;

    .filter {
      display: flex;
      align-items: center;
      margin-top: 12px;

      &-label {
        margin-right: 20px;

        label {
          display: flex;
          align-items: center;
          color: var(--pool-info-filter-text);
          cursor: pointer;
        }

        input[type="radio"] {
          width: 15px;
          height: 15px;
          cursor: pointer;

          &:checked:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -3.5px;
            left: 0px;
            position: relative;
            background-color: var(--pool-info-radio-color);
            content: '';
            display: inline-block;
            visibility: visible;
            border: 3px solid var(--pool-info-radio-bg-color);
          }
        }

        span {
          margin-left: 6px;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .text {
      font-size: 1.5rem;
      font-weight: 550;
      text-transform: uppercase;
      color: var(--text-color);
    }

    .filter {
      overflow-x: auto;
    }
  }

  &__table {
    overflow-x: auto;

    table {
      border-radius: 10px;
      width: 100%;
      overflow: hidden;
      color: var(--analytic-table-text);
      th,
      td {
        padding: 20px 30px;
        border-top:1px solid var(--poolinfo-table-border);
        a{
          color: var(--link-color-main);
        }
      }

      tr {
        border-bottom: var(--poolinfo-table-border);
      }

      thead {
        border-top: 2px solid var(--analytic-table-head);
        background: var(--analytic-table-head);

        tr {
          // border-bottom: 1px solid var(--background-gradient-1);

          th {
            font-weight: 550;
            text-transform: uppercase;
            color: var(--poolinfo-table-text);
          }
        }
      }

      tbody {
        background-color: var(--poolinfo-table-bg-body);

        tr:nth-child(even) {
          background-color: var(--poolinfo-table-stripped);
        }
      }
    }

    &__pagination {
      display: flex;
      justify-content: center;
      padding: 20px;

      .pg-button {
        // border-radius: 50%;
        // width: 45px;
        // height: 45px;
        border: none;
        background: transparent;
        // background: var(--poolinfo-pagination-bg);
        color: var(--poolinfo-pagination-text);
        margin: 4px;

      }

      .active {
        color: var(--text-accent-color);
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}

.token-card-table {
  height: 200px;

  .table {
    thead {
      th {
        padding: 10px 20px;
      }
    }

    tbody {
      td {
        padding: 10px 20px;
      }
    }
  }

}

@media (max-width: 991px) {
  .tx-table {
    &__table {
      margin: 0;
    }
  }
}