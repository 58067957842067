.pool-row {
  display: grid;
  grid-template-columns: 230px 200px 160px 160px auto auto;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  background-color: var(--poolrow-bg);
  color: var(--poolrow-content-text);
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: var(--modal-box-shadow);

  &-skeleton {
    grid-template-columns: auto;
    width: 100%;
  }

  .field {
    padding: 1rem;
    flex-grow: 1;
    display: flex;

    .pair {
      margin-left: 50px;
    }

    .pair-btn {

      width: 150px;
      box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 3px 3px 12px -3px rgba(22, 10, 10, 0.75);
      background: var(--pool-card-btn-bg-pair);

      a {
        color: var(--poolrow-pair-btn-text);
        text-decoration: none;
      }
    }

    .info-btn {
      width: 150px;
      box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 3px 3px 12px -3px rgba(22, 10, 10, 0.75);
      background: var(--pool-card-btn-bg-info);

      a {
        color: var(--poolrow-pair-btn-text);
        text-decoration: none;
      }
    }
    

    .action {
      cursor: pointer;

      &:hover {
        color: var(--poolrow-remove-btn);
      }
    }
  }
}