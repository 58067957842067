// DECLARE THE THEME VARIABLES
:root {
  --background-gradient-1: #2b5876;
  --background-gradient-2: #4e4376;

  --header-button-background: white;
  --header-button-color: black;
  --header-button-border-color: lightgrey;

    //modal slippage
    --modal--slippage-field-label:#04051d;
    // --modal-slippage-input:#0db2d7;
    // --modal-slippage-button-bg:#0db2d7;
    --modal-slippage-button-text:#fff;
}
