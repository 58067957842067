.block-num {
  cursor: pointer;
  z-index: 999;
  // width: 130px;
  position: fixed;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  right: 0px;
  bottom: 40px;
  padding: 1rem;
  color: rgb(39, 174, 96);
  transition: color 250ms ease 0s;

  &.inactive {

    color: rgb(174, 39, 39);

    .block-icon {
      color: rgb(174, 39, 39);
    }
  }

  .block-icon {
    font-size: 12px;
    margin-left: 2px;
    position: relative;
    color: rgb(39, 174, 96);
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  .blocks {
    opacity: 0;
    transition: opacity 0.8s ease 0s;
    font-size: small;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(134, 235, 248, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}
