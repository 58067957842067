.wallet-balance-main {
    display: none;

    @media (max-width: $lg-width) {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        background: var(--walletStatus-footer-bg);
        height: 50px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: fixed;
        bottom: 0;
        z-index: 11;
    }

    .xdc-connect-wrapper-footer {
        background: var(--header-button-bg) !important;
        padding: 4px;
        border-radius: 50px;
        color: var(--text-color);
        margin: 0 10px;
        
        .btn{
            font-weight: 600;
            padding: 2px 10px;
        }
    }


    .btn-dex-gradient-outline {
        background: var(--body-bg-color);
        color: var(--text-color);
        &:hover {
            background: var(--body-bg-color);
        }
    }

    .btn-dex-success {
        background: var(--header-xdc-button);
        color: var(--header-xdc-button-text);
        width: 100%;
    }

    .btn-dex-danger {
        background: var(--btn-danger-bg);
        color: var(--btn-danger-text);
        width: 100%;
    }


    .theme-changer,
    .ac-logout {
        color: var(--walletStatus-theme);
        cursor: pointer;
    }

    .wallet-status-container-footer {
        display: none;

        @media (max-width: $lg-width) {
            display: flex;
            align-items: center;
            color: var(--walletStatus-text);
            background: var(--walletStatus-bg);
            height: 36px;
            padding: 12px 3px 12px 12px;
            font-size: 18px;
            border-radius: 20px;
            font-weight: 600;
        }

        .ft-12{
            font-size: 12px;
        }

        .account {
            margin-left: 10px;
            background: var(--walletStatus-account-bg);
            color: var(--walletStatus-text-acc);
            height: 30px;
            display: flex;
            align-items: center;
            padding: 0 6px;
            border-radius: 20px;
            cursor: pointer;

            .account-inner {
                a {
                    margin: 0;
                    color: var(--walletStatus-text-acc);
                    text-decoration: none;
                }
            }
        }
    }
}