// -----------------------------------------------------------------------------------------
// COLOR STARTS
// -----------------------------------------------------------------------------------------

.blue {
  color: $color-new-blue;
}

.red {
  color: $color-new-red-light
}

.green {
  color: $color-new-green-light;
}

.gray {
  color: $color-new-gray;
}

.purple {
  color: var(--text-accent-color);

}

.orange {
  color: $color-new-orange;
}

.btn {
  color: #FFF;
  border-radius: 50px;
}

.btn:hover {
  color: #FFF;
}

.btn-outline{
  border: 4px solid var(--poolinfo-btn-bg-outline);
  color: var(--poolinfo-btn-text-outline-dark);
  margin: 10px;
  font-weight: 700;
  @media screen and (max-width: $xxsm-width) {
    margin: 4px;
  }
}
.btn-outline-small{
  border: 1px solid var(--poolinfo-btn-bg-outline);
  color: var(--poolinfo-btn-text-outline-dark);
  font-size: 14px;
  font-weight: 500;
  margin: 1px;
  padding: 2px 9px;
  @media screen and (max-width: $xxsm-width) {
    margin: 4px;
  }
}
.btn-outline-small:hover{
  color: var(--poolinfo-btn-text-outline-dark);
}
.btn-outline:hover{
  color: var(--poolinfo-btn-text-outline-dark);
}

.pill-danger {
  background: #d72d2d;
}

.pill-success {
  background: #28a745;
}

.pill-warning {
  background: #f8bc09;
}

.xdc-connect .Toastify__toast--success-tx {
  color: var(--text-accent-color) !important;
  border-left: 4px solid var(--text-accent-color);

  a {
    color: rgb(48, 48, 194) !important;
  }
}

.xdc-connect .Toastify__progress-bar--default {
  background: var(--toast-progress-color) !important;
}

.xdc-connect {

  .wallet-connect-block {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto;
    gap: 10px;

    .wallect-connect-btn {
      height: 100px;
      outline: none;
      box-shadow: none;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

input::placeholder {
  color: var(--input-placeholder-text) !important;
  opacity: 1;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--input-placeholder-text) !important;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--input-placeholder-text) !important;
}


// -----------------------------------------------------------------------------------------
// COLOR ENDS
// -----------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------
// RESPONSIVE STARTS
// -----------------------------------------------------------------------------------------

@media only screen and (min-width: $lg-width) {
  .d-none-xl {
    display: none !important;
  }

  .d-none-from-xl,
  .d-none-from-lg,
  .d-none-from-md,
  .d-none-from-sm {
    display: none !important;
  }

  .d-none-upto-xl {
    display: none !important;
  }
}

@media only screen and (min-width: $md-width) and (max-width: $lg-width) {
  .d-none-lg {
    display: none !important;
  }

  .d-none-from-lg,
  .d-none-from-md,
  .d-none-from-sm {
    display: none !important;
  }

  .d-none-upto-xl,
  .d-none-upto-lg {
    display: none !important;
  }
}

@media only screen and (min-width: $sm-width) and (max-width: $md-width) {
  .d-none-md {
    display: none !important;
  }

  .d-none-from-md,
  .d-none-from-sm {
    display: none !important;
  }

  .d-none-upto-xl,
  .d-none-upto-md,
  .d-none-upto-lg {
    display: none !important;
  }
}



// -----------------------------------------------------------------------------------------
// RESPONSIVE ENDS
// -----------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------
// MISC STARTS
// -----------------------------------------------------------------------------------------

.u-cursor-pointer {
  cursor: pointer;
}

.u-fit-modal {
  .modal-dialog {
    width: fit-content;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}

.no-outline {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.u-no-bullets {
  list-style-type: none;
}

.u-overflow-trim {
  overflow: hidden
}

// -----------------------------------------------------------------------------------------
// MISC ENDS
// -----------------------------------------------------------------------------------------

button {
  @extend .no-select;
  @extend .no-outline;
}


@media only screen and (max-width: $sm-width) {
  .d-none-sm {
    display: none !important;
  }

  .d-none-from-sm {
    display: none !important;
  }

  .d-none-upto-xl,
  .d-none-upto-md,
  .d-none-upto-lg,
  .d-none-upto-sm {
    display: none !important;
  }

  .u-fit-modal {
    .modal-dialog {
      width: auto;
    }
  }
}

.account-links a, .address-links a{
  color: var(--link-color-main);
}