.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  // background: linear-gradient(
  //   var(--background-gradient-1),
  //   var(--background-gradient-2)
  // );
  background: var(--body-bg-color);

  .inner-wrapper {
    flex: 1 0 auto;

    .dashboard {
      flex-grow: 1;
    }
  }
}

.home-message-box{
  margin-top: 60px;
    text-align: center;
    background: #e91e632b;
    padding: 0px;

}
.dex-celebration{
  width: 2.5%;
}
.dex-celebration-two{
  width: 3.2%;
}


.homepage-message p{
  padding: 6px;
    color: #fff;
    font-size: 14px !important;
     margin-bottom: 0px !important;
}
.MessageClose{
 float: right;
    font-size: 13px;
    padding: 7px 5px;
    font-weight: 700;
    cursor: pointer;
}
.MessageClose:hover{
    font-size: 14px;
}
.homepage-message p a{
  text-decoration: none;
  font-size: 14px;
  color: #2196f3;
    font-weight: 600;
}

a:hover{
  text-decoration: none;
}
.list-section{
 /* box-shadow: 0 4px 20px #aa3a56d4, 11px 1px 12px #ee17602b;
    margin: 5px 0px;
    background: #e91e632b; */
    padding: 10px;
    border-radius: 5px;
}
.main-top{
  padding: 0px 80px 0px 80px !important;
}
.list-section-top{
  margin-top: -5%;
}
.list-section .content{
  justify-content: center !important;
}
.content-list-section{
  display: contents;
}
.content-list-section div{
    width: 17%;
    background: #9e9e9e17;
    border-radius: 8px;
    padding: 20px 10px;
    height: 150px;
    cursor: pointer;
    text-align: center;

    @media (min-width: 300px) and (max-width: 480px)  {
      width: 90%;
    }

    @media (min-width: 600px) and (max-width: 719px) {
      width: 50%;
    }

    @media (min-width: 720px) and (max-width: 1024px) {
      width: 50%;
    }

    @media (min-width: $xlg-width3) {
      width: 90%;
      height: 190px;
    }
    @media (min-width: 1900px) {
      height: 210px;
    }
    
}

.content-list-section div span{
  font-size: 15px;
    font-weight: bold;
    color: #03A9F4;
  
}

.content-list-section div:hover{
  background: #9e9e9e3d;
}
.content-list-section div:hover span{
  font-size: 16px;
}
.content-list-section div:hover .list-section-img{
  padding: 13px;
}
.list-section-img{
   width: 100%;
   padding: 15px;
  -webkit-filter: drop-shadow(1px 2px 2px #607D8B);
  filter: drop-shadow(1px 2px 2px #607D8B);

  @media (min-width: 300px) and (max-width: 480px)  {
    width: 80%;
  }

  @media (min-width: 600px) and (max-width: 800px)  {
    width: 80%;
  }


  @media (min-width: $xlg-width3) {
    width: 85%;
  }
  @media (min-width: 1900px) {
    width: 90%;
  }
}
.list-section-img-light{
  filter: invert(1);
}
.list-section-img-dark{
  filter: drop-shadow(2px 4px 6px black);
}
.content-list-section div:nth-child(2) .list-section-img {
  margin: 8px 0px;
  @media (min-width: 1680px) and (max-width: 1920px)  {
    margin: 20px 0px;
  }
}
.content-list-section div:nth-child(3) .list-section-img {
  margin: 7px 0px;
  @media (min-width: 1680px) and (max-width: 1920px)  {
    margin: 20px 0px;
  }
  
}

.list-section-p{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #03A9F4;
    margin-bottom: 5px;
}
.list-section-p-dark{
  color: #fff;
  margin-bottom: 5px;
  text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.list-section-p span{
  border-bottom: 2px solid;
    padding: 1px 5px;

}