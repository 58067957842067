.page-error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 8rem 0rem;
    .main-title {
        font-size: 12rem;
        font-weight: 700;
        color: var(--text-accent-color);
        line-height: 12rem;

        @media screen and (max-width:$md-width){
            font-size: 8rem;
            line-height: 10rem;
        }
    }

    .main-description {
        font-size: 2.9rem;
        font-weight: 500;
        letter-spacing: 2px;
        color: var(--text-accent-color);
        text-align: center;
        @media screen and (max-width:$md-width){
            font-size: 1rem;
        }
    }

    a {
        text-decoration: none;
        .go-back {
            cursor: pointer;
            width: 250px;
            background: var(--error-page-bt-bg);
            text-align: center;
            padding: 24px 30px;
            color: var(--error-page-bt-text);
            border-radius: 10px;
            box-shadow: var(--error-page-bt-shadow);
            margin-top: 20px;
            font-weight: 500;
            font-size: 18px;
            @media screen and (max-width:$md-width){
                font-size: 16px;
            }

        }
    }
}