.__light {
  --background-gradient-1: #2b5876;
  --background-gradient-2: #4e4376;

  --header-button-background: whitesmoke;
  --header-button-background--hover: lightgrey;
  --header-button-color: black;
  --header-button-border-color: #2b5876;

  --text-primary-color: #04051d;
  --text-accent-color: #0fa9d2;
  --text-secondary-color: #2a2e97;

  --background-primary: #04051d;
  --background-accent: #0fa9d2;
  --background-secondary: #2a2e97;


  //landing bg
  --body-bg-color: #04051d;
  --header-bg-color: transparent;
  --header-box-shadow: none;
  --text-color: #ffffff;
  --header-button-bg: linear-gradient(90deg, #0fa9d2, #2a2e97);
  --header-button-text: #ffffff;
  --header-button-bg-hover: linear-gradient(90deg, #2a2e97, #0fa9d2);
  --header-xdc-button: #ffffff;
  --header-xdc-button-text: #2a2e97;


  //landing Page colors
  --landing-content-margin-bt:0;
  --landing-dex-text-h1: #FFF;
  --landing-dex-text-h3: #FFF;
  --landing-dex-text-align: center;
  --landing-dex-text-weight: 100;
  --color-text-light-blue: #0db2d7;
  --color-text-yellow: #ffc512;

  //Header Colors
  --header-dropdown-bg: #191b74;
  --header-dropdown-bg-hover: #313292;
  --header-dropdown-text-color: #ffffff;

  //Mobile Nav bg
  --bg-mob-nav-hover: #2a2e97;
  --bg-mob-nav-hover-text: #fff;
  --bg-nav-burger: #fff;
  --bg-nav-burger-shadow: 0 10px 0 #fff;

  //landing page layout
  --body-text: #FFF;
  --main-hero-text: flex-end;
  --main-hero-text-align: inherit;
  --lptoken-text-align: center;
  --swap-token-padding: 80px 80px;
  --swap-content-margin: 0;

  //large device layout
  --main-hero-text-large: space-evenly;

  //Swap Button pill colors
  --bg-pill-button: #ffffff;
  --pill-button-text: #000000;
  --swap-button-bg: #0db2d7;
  --token-box-bg: #f7f7f7;
  --token-box-border: 1px solid #d3d3d3;
  --token-box-border-focus: 1px solid #0db2d7;
  --input-text-bg: #f7f7f7;

  //Swap text
  --title-text-color: #0d062d;
  --subTitle-text-color: #555469;
  --swap-text-input: #555469;

  // swap icons color
  --swap-icon-color: #0db2d7;

  //swap-modal
  --modal-border: #0db2d7;
  --modal-bg-color: #04051d;
  --modal-border-color: 1px solid #0db2d7;
  --modal-list-hover: #272a7370;
  --modal-box-shadow: 0px 3px 4px rgba(6, 218, 255, 0.527);

  //pool
  --pool-search-placeholder: #000;
  --pool-card-btn-bg-pair: #0db2d7;
  --pool-card-btn-bg-info: #0db2d7;
  --pool-action-bg: #fff6;
  --pool-search-icon-input-color: #000;

  //pool info
  --poolinfo-liquidity-bg: #ffffff;
  --poolinfo-locked-token-bg: #faf9fa;
  --poolinfo-pagination-bg: #0db2d7;
  --poolinfo-pagination-text: #ffff;
  --poolinfo-chart-button-bg: #0d062d;
  --poolinfo-chart-button-text: #FFF;
  --poolinfo-chart-button-active: #0db2d7;
  --poolinfo-table-bg-body: #ffffff;
  --poolinfo-table-text: #fff;
  --poolinfo-table-body-text:#000;
  --poolinfo-table-border: #929292;
  --poolinfo-table-shadow: 0px 0px 6px 3px rgba(13, 178, 215, 0.5);
  --poolinfo-table-stripped: #f1f1f18a;
  --poolinfo-token-shadow: rgba(42, 47, 152, 0.603) 0px 1px 1px,
    rgba(42, 47, 152, 0.753) 0px 1px 1px,
    rgba(42, 47, 152, 0.466) 0px 0px 0px 1px inset;
  --poolinfo-stats-bg: #fff;
  --poolinfo-stats-text: #000;
  --poolinfo-stats-label: #0db2d7;
  --poolinfo-stats-locked-token-bg: #f3f3f3;
  --poolinfo-stats-locked-token-text: #000;
  --poolinfo-btn-bg-outline: #0db2d7;
  --poolinfo-btn-bg: #0db2d7;
  --poolinfo-btn-text-outline: #FFF;
  --pool-card-bg:#fff;
  --pool-card-title-text:#000;
  --pool-card-remove:#000;

  //tokenlist
  --addToken-bg: #0a0c40c2;


  //footer
  --footer-bg: #04051d;
  --footer-border: #2a2e97;
  --footer-copyright-text: #ffffff;
  --footer-social-icons-border: 3px solid #ffffff;
  --footer-social-icons-border-res: 2px solid #ffffff;

  //header image
  --header-bg-img: url("../../dex/space-bg.png");
  --xrc-content-img: url("../../dex/swap-bg.png");
  --lp-token-content-img: url("../../dex/gbex-coin-bg.png");

  //modal slippage
  --modal--slippage-field-label: #04051d;
  --modal-slippage-input: #0db2d7;
  --modal-slippage-button-bg: #0db2d7;
  --modal-slippage-button-text: #fff;

  //analytics
  --bar-chart-day-text: #FFF;
  --bar-chart-day-text-active: #FFF;
  --bar-chart-text-heading: #FFF;
  --table-text-color: #FFF;
  --table-border-td-bg-color: #dad7d7;
  --table-border-color: #fff;
  --analytic-table-head: #0fa9d2;

  //tvl
  --tvl-bg: #8dc8e96e;
  --tv-text: #fff;

  //dex box stats
  --bg-dex-counter: #191b74;
  --bg-dex-counter-border: #9e9fe3;
  --dex-text-color: 1px solid #4649ca;

  //toggle
  --toggle-bg: #f7f7f7;
  --toggle-text: #10a2ce;

  --tokenPrice-hover:#10a2ce;
  --tokenPrice-text-hover: #FFF;

  --learnMore-bg:#10a2ce;
  --learnMore-text:#fff;

  --faq-main-text:#10a2ce;
  --faq-title-text:#10a2ce;
  --faq-list-text:rgb(0, 0, 0);
  --faq-icon-bg:#10a2ce;
  --faq-box-shadow:rgba(18, 113, 142, 0.11) 0px 0px 4px 0px inset, rgba(218, 218, 218, 0.25) 0px 50px 100px -20px, rgba(114, 229, 249, 0.137) 0px 30px 60px -30px;
  --faq-section-bg:#FFF;
  --faq-link:#3737ff;

  --toast-progress-color:#10a2ce;
  --input-placeholder-text:gray;

  
  --chart-loaderbg-1:#19a0c9bb;
  --chart-loaderbg-2:#19a0c9bb;
  --chart-loaderbg-3:#19a0c9bb;


  --custom-skeleton--highlight: #1096be21;
  --custom-skeleton--bg: #ebebeb;
  --base-color-bg:#ebebeb;
  --custom-skeleton--bh: #ebebeb;

  --coinPair-text:#FFF;
  --btn-text:#FFF;

  --notifiy-info-bg:#808080;
  --notifiy-info-text:#ffffff;
  --notifiy-success-bg:#808080;
  --notifiy-success-text:#ffffff;
  --notifiy-warning-bg:#808080;
  --notifiy-warning-text:#ffffff;
  --notifiy-processing-bg:#808080;
  --notifiy-processing-text:#ffffff;
  --notifiy-error-bg:#808080;
  --notifiy-error-text:#ffffff;

  --pool-action-btn-bg:#c7c1c1;
  --pool-action-btn-text:#000;
  --pool-action-btn-a-bg:#ffffff;
  --pool-action-btn-a-border:#d3d3d3;

  
  --btn-danger-bg:#ec4134;
  --btn-danger-text:#ffff;

  --modal-setting-bg:#FFF;
  --modal-ack-bg:#FFF;
  --modal-ack-text:#000;

  --modal-setting-popover-text:#FFF;
  --modal-setting-popover-bg:#000000d9;
  --modal-setting-bg-input:#fff;

  --analytic-table-bg:#fff;
  --analytic-table-even-td:#f1f1f18a;
  --analytic-table-pagination-text:#fff;

  --token-header-bg: #FFF;
  --token-header-text: #000;
  --token-header-hvr-text: #FFF;
  --token-content-bg:#FFF;

  --token-load-border:#d3d3d3;
  --token-load-body-name-text:#808080;
  --token-load-body-name-hover:#d3d3d3;

  
  --token-list-custom-action-text:#FFF;
  --token-list-custom-list-border:#d3d3d3;
  --token-list-custom-list-body-name-text:#808080;
  --token-list-custom-list-add-text:#ffffff;
  --token-list-custom-list-no-token-list:#ffffff;
  --token-list-custom-list-active-bg:#f5f5f5;

  
  --swap-bg:#FFF;
  --swap-input-placeholder:#808080;
  --swap-subtext:#808080;
  --swap-inversrate-text:#808080;
  --swap-route-text:#fff;
  --swap-route-path-bg:#000000d9;
  --swap-route-path-text:#fff;
  --swap-route-vtext-text:#4241417d;
  --swap-route-coinchain-bg:#645e60;
  --swap-route-coinchain-text:#FFF;
  --swap-route-dot:#FFF;
  --swap-submit-text:#FFF;
  --swap-submit-border:#d3d3d3;
  --swap-field-bg:#f5f5f5;
  --swap-field-border:#d3d3d3;

  --poolcard-content-bg:#f0f8ff;
  --poolcard-content-value-text:#000;
  --poolcard-content-remove:#ff0000;
  --poolcard-content-btn-text:#FFF;

  --poolrow-bg:#ffffff;
  --poolrow-content-text:#000;
  --poolrow-border: #d3d3d3;
  --poolrow-pair-btn-text:#ffffff;
  --poolrow-remove-btn:#ff0000;

  --poolInfo-btn-text: #FFF;
  --poolInfo-amt-usd-text: #04051d;

  --pool-active-btn: #FFF;
  --display-view-btn-bg: #fff;
  --display-view-btn-text: #000 ;
  --display-view-active-btn: #10a2ce;

  --pool-info-radio-bg-color:#10a2ce;
  --pool-info-radio-color:#2a2e97;
  --pool-info-filter-text:#FFF;
  --poolinfo-btn-text-outline-dark: #10a2ce;
  --poolManage-balance-bg:#FFF;
  --poolManage-balance-token-bg:#10a2ce;
  --poolManage-balance-token-text:#FFF;
  --poolManage-lpbalance-input-text:#10a2ce;
  --poolManage-lpbalance-input-border:#10a2ce;
  --poolManage-connect-btn-bg:#10a2ce;
  --poolManage-connect-btn-text:#FFF;
  --poolManage-connect-btn-border:#0000;
  
  --token-info-tooltip-text:#10a2ce;
  --token-info-tab-button-text:#000;
  --token-info-tab-button-active-text:#10a2ce;
  --token-info-stats-text:#10a2ce;

  --clipboard-bg:#2a2e97;
  --clipboard-text:#10a2ce;

  --token-btn:#FFF;
  --token-btn-text:#10a2ce;
  --token-btn-hvr:#10a2ce;
  --token-btn-hvr-text:#FFF;

  --notifi-bg:#10a2ce59;
  --notifi-text:#FFF;

  --error-page-bt-text:#FFF;
  --error-page-bt-bg:#10a2ce;
  --error-page-bt-shadow: 0px 0px 6px 3px #0fa9d282;

  --filter-switch-chart: #000;
  --analytic-table-text:#000;

  --token-info-stats-text1:#280d5f;
  --token-info-tab-button-active:#FFF;
  --manage-pool-text-color: #000;
  --link-color-main: #1111d3;
  --link-color-main-hvr: #1300c2;

  --token-bottom-color:#c0c0c0;

  --walletStatus-bg: #10a2ce;
  --walletStatus-account-bg: #04051d;
  --walletStatus-text: #FFF;
  --walletStatus-text-acc: #FFF;
  --walletStatus-footer-bg:#070935;
  --walletStatus-theme:#FFF;
  --maintenance-text:#FFF;
}