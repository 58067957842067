.token-list-wrapper {
  background: var(--modal-bg-color);
  width: 400px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid var(--modal-border) ;
  
  @media screen and (max-width: $sm-width) {
    width: auto !important;
  }
  
  @media screen and (max-width: $md-width) {
    margin-top: 3rem;
  }

  .search-box {
    padding: 5px;
  }

  .token-list {
    @extend .u-no-bullets;

    background-color: var(--modal-bg-color);
    padding: 1rem 0 1rem 0;
    height: 300px;
    overflow-y: scroll;

    .custom__new {
      
    }

    .custom {
      background:var(--addToken-bg);
      .action{
        color: var(--token-list-custom-action-text);
      }
    }

    li {
      @extend .u-cursor-pointer;

      .token {
        display: flex;
        flex-grow: 1;
      }

      .action {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-right: 5px;
      }

      display: flex;
      padding: 5px;
      border-bottom: 1px solid var(--token-list-custom-list-border);

      .logo,
      .body,
      .balance {
        margin: 0 5px 0 5px;
        color:var(--text-color);
      }

      .body {
        flex-grow: 1;
        color: var(--text-color);
        
        .name {
          font-size: small;
          color: var(--token-list-custom-list-body-name-text);
        }
      }

      .add-custom{
        background: var(--swap-button-bg);
        color: var(--token-list-custom-list-add-text);
        border-radius: 30px;
        padding: 10px 20px;
      }

      .balance {
        display: grid;
        align-items: center;
        margin-right: 10px;

        img {
          width: 15px;
        }
      }

      .logo {
        margin-left: 20px !important;
        height: 40px;
        width: 40px;
      }

      &:hover {
        background-color: var(--modal-list-hover);
      }
    }

    li.no-token {
      display: block;
      pointer-events: none;
      text-align: center;
      color: var(--text-color);
      border: 1px solid var(--token-list-custom-list-no-token-list);
    }

    li.active {
      background-color: var(--modal-list-hover);
      pointer-events: none;
    }
  }

  .common-bases-text {
    color: var(--text-color);
    font-size: 20px;
    margin: 10px 0px 0px 10px;
  }

  .common-bases {
    @extend .u-no-bullets;
    background-color: var(--modal-bg-color);
    color: var(--text-color);
    display: flex;
    padding: 1rem 0 1rem 0;
    margin: 0;
    flex-wrap: wrap;

    .logo,
    .body {
      margin: 0 5px 0 5px;
    }

    li {
      @extend .u-cursor-pointer;
      display: flex;
      align-items: center;
      box-shadow: var(--modal-box-shadow);
      border-radius: 5px;
      margin: 10px;
      padding: 5px;
      height: 40px;

      .logo {
        height: 30px;
        width: 30px;
      }

      .body {
        display: grid;
        align-content: center;
      }
    }

    li.active {
      background-color:var(--token-list-custom-list-active-bg);
      pointer-events: none;
    }
  }
}
