.stake-row-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8rem 2rem;

    .stake-data {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        background: #FFF;
        width: 100%;
        align-items: center;
        height: 80px;
        margin-bottom: 10px;
        border-radius: 10px;

        .coin {
            img {
                width: 50px;
            }
        }
    }
}