  .dashboard{
    display: flex;
    justify-content: center;
    height: 100%;

    .landing-main-2 {
      margin-top: 4.5rem;
      margin-left: 4rem;

      @media screen and (max-width: $lg-width) {
        display: none;
      }

      img {
        width: 90%;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
      // &#add_liquidity_pinkgirl, &#swap-pinkgirl {
      //   width: 300px !important;
      //   img {
      //     width: 183px !important;
      //     height: 480px;
      //     object-fit: cover;
      //   }
      // }
    }

    .swap {
      margin-top: 8rem;
      margin-bottom: 5rem;
      @media screen and (min-width: $xlg-width2) {
        margin-left: 6rem;
      }
    }

  }

