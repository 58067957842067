.Toastify {

  a {
    color: $color-new-blue;
  }

  /** Used to define container behavior: width, position: fixed etc... **/
  &__toast-container {
    /** Used to define the position of the ToastContainer **/
    &--top-left {
    }
    &--top-center {
    }
    &--top-right {
    }
    &--bottom-left {
    }
    &--bottom-center {
    }
    &--bottom-right {
    }
  }

  /** Classes for the displayed toast **/
  &__toast {
    &--rtl {
    }
    &--dark {
    }
    &--default {
    }
    &--info {
      color: var(--notifiy-info-text);
      background: var(--notifiy-info-bg) !important;
    }
    &--success {
      color: var(--notifiy-info-text);
      background: var(--notifiy-info-bg) !important;
    
    }
    &--warning {
      color: var(--notifiy-info-text);
      background: var(--notifiy-info-bg) !important;
    
    }
    &--processing-tx {
      color: var(--notifiy-info-text);
      background: var(--notifiy-info-bg) !important;
    
      border-left: 4px solid $color-new-orange;
    }
    &--success-tx {
      color: var(--notifiy-info-text);
      background: var(--notifiy-info-bg) !important;
      border-left: 4px solid $color-new-green;
    }
    &--error {
      color: var(--notifiy-info-text);
      background: var(--notifiy-info-bg) !important;
      border-left: 4px solid $color-new-red;
    }
    &-body {
    }
  }

  /** Classes for the close button. Better use your own closeButton **/
  &__close-button {
    color: black !important;

    &--default {
    }
    & > svg {
    }
    &:hover,
    &:focus {
    }

    &--success-tx {
      color: black !important;
    }
  }

  /** Classes for the progress bar **/
  &__progress-bar {
    &--animated {
    }
    &--controlled {
    }
    &--rtl {
    }
    &--default {
      background: $color-light-blue !important;
    }
    &--dark {
    }
    &--warning {
      background: $color-new-orange !important;
    }
    &--success {
      background: $color-new-green !important;
    }
    &--error {
      background: $color-new-red !important;
    }
  }
}
