.swap {
  padding: 1rem;
  background-color: var(--swap-bg);
  color: var(--title-text-color);
  width: 490px;
  height: fit-content;
  border-radius: 40px;
  margin: 1rem;

  .header {
    display: flex;
    flex-direction: row;

    .text {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      &__primary {
        font-size: 2rem;
        font-weight: 600;
        color: var(--title-text-color);

        @media (max-width: 600px) {
          font-size: 1.6rem;
        }
      }
    }

    .settings {
      margin: 0.5rem;
      padding-bottom: 0.5rem;

      svg {
        cursor: pointer;
        color: var(--swap-icon-color);
      }
    }
  }

  .toggle-container{
    display: flex;
    justify-content: center;
    position: relative;
    margin: -15px;
    z-index: 2;

  .toggle {
    font-size: 13px;
    font-weight: 100;
    height: 30px;
    width: 30px;
    padding: 3px 3px;
    background: var(--toggle-bg);
    color: var(--toggle-text);
    border: 2px solid;
    border-radius: 50%;

    svg {
      cursor: pointer;
    }
  }
} 

  .token-input {
    border: var(--token-box-border);
    padding: 1rem;
    margin: 0em 0.5em;
    border-radius: 20px;
    background-color: var(--token-box-bg);
    display: flex;

    &:focus-within {
      border: var(--token-box-border-focus);
    }
    

    * {
      grid-column-start: 1;
      grid-row-start: 1;
      justify-self: center;
    }

    .token-select {
      justify-self: left;
      cursor: pointer;
      background-color: var(--bg-pill-button);
      // padding: 8px;
      color: var(--pill-button-text);
      display: grid;
      align-content: center;
      border-radius: 20px;
      height: fit-content;
      box-shadow: 0 3px 8px #0000001a;

      .select-token {

        .token-name,
        svg {
          margin-left: 5px;
          margin-right: 5px;
        }

        .token-name {
          display: flex;
          align-items: center;
          padding: 8px;
          img {
            width: 25px;
            height: 25px;
            margin-left: 5px;
            margin-right: 5px;
          }
        }

        // svg {
        //   grid-column: 1;
        // }
      }
    }

    .lp-amt{
      padding: 10px;
    }

    .token-amount {
      justify-self: right;
      flex-grow: 1;

      input {
        text-align: right;
        border: none;
        color: var(--swap-text-input);
        background-color: var(--input-text-bg);
        font-size: large;

        &:focus,
        &:focus-within,
        &:focus-visible {
          border: none !important;
          box-shadow: none !important;
          outline: none;
        }
      }

      .sub-text {
        height: 1rem;
        text-align: right;
        font-size: small;
        color: var(--swap-subtext);
      }

      .max {
        text-align: right;
        height: 1rem;

        span {
          color: $color-new-blue;
          @extend .u-cursor-pointer;
          font-size: small;
        }
      }
    }
  }

  .active-rmv {
    border: var(--token-box-border-focus);
  }

  .inverse-rate {
    display: flex;
    flex-direction: column;
    margin: 0.5em;
    margin-bottom: 2rem;
    color: var(--swap-inversrate-text);

    .minimum-token-out {
      text-align: right;
      font-size: 12px;
      font-weight: 450;
    }

    .fott {
      text-align: right;
      font-size: 12px;
      font-weight: 450;
    }

    .rate {
      align-self: flex-end;
      font-size: 12px;
      font-weight: 450;
    }

    .router {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .path-box {
        margin-left: 6px;
        

        .route-b.active {
          background-color: var(--text-secondary-color);
        }

        .route-b {
          background-color: var(--text-accent-color);
          border-radius: 20px;
          color: var(--swap-route-text);
          cursor: pointer;
          display: inline-block;
          font-size: 12px;
          font-weight: bold;
          height: 20px;
          line-height: 20px;
          position: relative;
          text-align: center;
          width: 20px;

          .path {
            max-height: 200px;
            overflow-y: auto;
            background-color: var(--swap-route-path-bg);
            border-radius: 5px;
            box-shadow: 0 0 5px #00000066;
            color: var(--swap-route-path-text);
            display: block;
            font-size: 12px;
            padding: 7px 10px;
            position: absolute;
            max-width: 300px;
            width: 300px;
            z-index: 20;
            margin: -18px 22px;
            overflow-y: auto;

            .c-header {
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid;
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 5px;
              .vtext{
                background: var(--swap-route-vtext-text);
                border-radius: 5px;
                padding: 1px 10px;
              }
            }

            .c-body {
              .icon-set {
                display: flex;
                margin: 8px;
                padding: 6px;
                border-radius: 10px;
                justify-content: space-between;
                cursor: pointer;
                img{
                  width: 20px;
                  height: 20px;
                }
                .coin-chain{
                  background: var(--swap-route-coinchain-bg);
                  padding: 2px;
                  border-radius: 5px;
                  color: var(--swap-route-coinchain-text);
                  .img-2{
                    margin-left:-8px ;
                  }
                }
                &:hover{
                  background: var(--text-accent-color);
                }
              }


              .active{
                background: var(--text-accent-color);
                
              }

              .dot-line {
                border-top: 4px dotted var(--swap-route-dot);
                width: 90%;
                margin-top: 8px;
              }
              .dot-line:last-child{
                display: none;
              }
            }

            .search-path{
              .input{
                width: 100%;
                margin: 4px 0px 0px;
                border-radius: 12px;
                border: none;
                outline: none;
                padding: 5px 10px;
              }
            }
          }
          // &:hover {
          //   .path {
          //     display: block;
          //     -webkit-animation: fade-in .3s linear 1, move-up .3s linear 1;
          //     -moz-animation: fade-in .3s linear 1, move-up .3s linear 1;
          //     -ms-animation: fade-in .3s linear 1, move-up .3s linear 1;
          //   }
          // }

        }
        

        @-webkit-keyframes fade-in {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        @-moz-keyframes fade-in {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        @-ms-keyframes fade-in {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        @-webkit-keyframes move-up {
          from {
            bottom: 30px;
          }

          to {
            bottom: 42px;
          }
        }

        @-moz-keyframes move-up {
          from {
            bottom: 30px;
          }

          to {
            bottom: 42px;
          }
        }

        @-ms-keyframes move-up {
          from {
            bottom: 30px;
          }

          to {
            bottom: 42px;
          }
        }
      }
    }
  }

  .submit {
    cursor: pointer;
    margin: 0.5em;
    border-radius: 5px;

    button {
      border-radius: 30px;
      padding: 1rem;
      background-color: var(--swap-button-bg);
      width: 100%;
      color: var(--swap-submit-text);
      text-transform: uppercase;
      border: 1px solid var(--swap-submit-border);
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .field {
    display: flex;
    margin: 0.5rem;
    border-radius: 5px;
    padding: 1rem;
    background-color: var(--swap-field-bg);
    border: 1px solid var(--swap-field-border);

    .label,
    .value {
      width: 100%;
      padding: 0.5rem;

     
    }

    .save {
      button {}
    }

    .label {
      min-width: 10rem;
    }

    .value {
      input {
        text-align: right;
        border: none;
        width: 100%;
        &:focus,
        &:focus-within,
        &:focus-visible {
          border: none !important;
          box-shadow: none !important;
          outline: none;
        }
      }
    }
  }

  .import-address {
    border: var(--token-box-border);
    padding: 1rem;
    margin: 0.5em;
    border-radius: 20px;
    background-color: var(--token-box-bg);
    display: flex;

    .label, .value {
      padding-left: 1rem;
    }

    .value {
      flex-grow: 1;
      text-align: right;
    }
  }

  .create-pair-link{
    text-align: center;
  }
}

.settings {
  .field {
    display: flex;

    .label,
    .value {
      width: 100%;
      padding: 0.5rem;
    }

    .save {
      button {}
    }

    .label {
      min-width: 10rem;
    }

    .value {
      input {
        text-align: right;
        border: none;
        width: 100%;
        &:focus,
        &:focus-within,
        &:focus-visible {
          border: none !important;
          box-shadow: none !important;
          outline: none;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .settings {
    padding: 10px 4px;

    .field {
      .label {
        min-width: auto;
      }
    }
  }
}