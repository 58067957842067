.maintenance-container{
    margin: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:$md-width){
        margin: 80px 10px 10px 10px;
    }
    .logo{
        margin-bottom: 5rem;
    }
    .main-title {
        font-size: 4rem;
        font-weight: 700;
        color: var(--text-accent-color);
        line-height: 4rem;

        @media screen and (max-width:$md-width){
            font-size: 3rem;
            line-height: 3rem;
        }
    }

    .text-des{
        font-size: 1.8rem;
        text-align: center;
        color: var(--maintenance-text);
    }

    i{
        font-size: 12rem;
        color: var(--text-accent-color);
    }
}