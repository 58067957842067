.pool-card {
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: var(--pool-card-bg);
  border-radius: 30px;
  width: 360px;
  box-shadow: var(--modal-box-shadow);
  .field {
    &.header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4rem;
      font-size: 1.5rem;
    }

    padding: 5px;
    display: flex;
    flex-direction: row;

    .value{
      color:var(--pool-card-title-text);
    }
    .label,
    .value {
      padding: 16px;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--poolcard-content-bg);
    box-shadow: 0px 0px 3px 0px rgba(68, 68, 68, 0.75);
    border-radius: 15px;
    margin-top: 20px;
    .label,
    .value {
      padding: 5px;
      color: var(--poolcard-content-value-text);
    }
  }

  .action {
    &-rmv{
      float: right;
      cursor: pointer;
      padding: 12px;
      color: var(--pool-card-remove);
      &:hover{
        color: var(--poolcard-content-remove);
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 30px 0px 10px 0px;

    .info {
      @media screen and (max-width: $xxsm-width) {
        margin-bottom: 10px;
      }
      .btn {
        background: var(--pool-card-btn-bg-pair);
      }
    }

    .action,.stake-btn-wrapper {
      .btn {
        background: var(--pool-card-btn-bg-info);
      }
    }

    .info,
    .action {
      @media screen and (max-width: $xxsm-width) {
        width: 100%;
      }
      &-rmv{
        width: 100%;
        text-align: center;
        cursor: pointer;
        &:hover{
          color: var(--poolcard-content-remove);
        }
      }
      .btn {
        @media screen and (max-width: $xxsm-width) {
          width: 100%;
        }
        width: 150px;
        box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 3px 3px 12px -3px rgba(22, 10, 10, 0.75);
        a {
          color: var(--poolcard-content-btn-text);
          text-decoration: none;
        }
      }
    }

    .stake-btn-wrapper{
      width: 100%;
      margin: 10px 0;
      .stake-btn{
        width: 100%;
        box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 3px 3px 12px -3px rgba(22, 10, 10, 0.75);
      }

    }
  }
}
