.analytics {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 120px;
    @media (max-width: $lg-width) {
        padding: 0px;
    }
    .spinner {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .chart-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        height: 350px;
        margin: 8rem 0 5rem 0;
        padding-top: 30px;

        @media (max-width: $lg-width) {
            flex-direction: column;
            padding: 20px;
            height: auto;
        }

        .chart {
            width: 46%;

            h3 {
                color: var(--bar-chart-text-heading);
                text-transform: uppercase;
                font-size: 20px;
                font-weight: 600;
            }

            @media (max-width: $lg-width) {
                width: 100%;
                height: 320px;
                margin-bottom: 30px;
            }

            .chartData {
                width: 100%;
                height: 300px;
                border: 2px solid var(--text-accent-color);
                border-radius: 4px;
                overflow: hidden;

                .img-loader{
                    display: flex;
                    justify-content: center;
                    img{
                        width: 200px;
                    }
                }

                .recharts-responsive-container {
                    margin: 30px 0 0 0;
                    height: 242px !important;

                    @media (max-width: $md-width) {
                        height: 264px !important;
                    }
                }

                .chart-title {
                    font-size: 16px;
                    font-weight: 600;
                }

                .day-filter {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                    .day {
                        margin: 10px;
                        padding: 5px 10px;
                        cursor: pointer;
                        color: var(--bar-chart-day-text);
                        border-radius: 5px;

                        &:hover {
                            background: var(--background-secondary);
                            ;
                            color: var(--bar-chart-day-text-active);
                        }
                    }

                    .active {
                        background: var(--background-secondary);
                        color: var(--bar-chart-day-text-active);
                    }

                }
            }

            .toolTip-wrapper {
                visibility: visible;
                transform: none;
                line-height: 10px;
                color: var(--text-accent-color);
            }

            .date-tooltip {
                font-size: 18px;
            }

            .tooltip-data {
                width: 100%;
            }
        }


    }

    .liquidityTable {
        h2 {
            text-transform: uppercase;
            font-size: 20px;
            padding: 10px 40px;
            font-weight: 600;
            margin-top: 20px;
            color: var(--bar-chart-text-heading);
            display: flex;
            align-items: center;
            cursor: pointer;

            .clipboard {
                display: none;
  
                .copy-clipboard {
                  margin-left: 10px;
                  cursor: pointer;
                }
              }

              &:hover {
                  .clipboard {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--clipboard-text);
                    .clip-pop {
                      position: absolute;
                      background: var(--clipboard-bg);
                      padding: 1px 10px;
                      font-size: 14px;
                      margin-top: -48px;
                      margin-left: 30px;
                      border-radius: 10px;
                      @media (max-width: $xxsm-width) {
                        margin-top: -30px;
                        margin-left: 19px;
                      }
                    }
                  }
              }
            
        }

        .react-bootstrap-table {
            margin-bottom: 10px;


            @media (max-width: $lg-width) {
                overflow-x: auto;
                padding: 20px;
            }

            padding: 0 40px;

            .table {
                color: var(--analytic-table-text);
                border: none;
                table-layout: auto;
                background: var(--analytic-table-bg);
                border-radius: 10px;
                overflow: hidden;
                text-transform: uppercase;
                thead {
                    @extend .no-select;
                    @extend .no-outline;
                    // border-bottom: 2px solid var(--table-border-color);
                    background: var(--analytic-table-head);
                    color: var(--table-text-color);
                    th {

                        @extend .no-select;
                        @extend .no-outline;

                        font-weight: 400;
                        text-align: right;
                        padding: 20px 20px;
                        // border-right: 1px solid;
                        &:nth-child(1) {
                            text-align: left;
                        }
                    }

                    .caret {
                        display: inline-block;
                        width: 0;
                        height: 0;
                        margin-left: 2px;
                        vertical-align: middle;
                        border-top: 4px dashed var(--table-border-color);
                        border-top: 4px solid \9;
                        border-right: 4px solid transparent;
                        border-left: 4px solid transparent;
                    }

                    .dropup .caret,
                    table thead .navbar-fixed-bottom .dropdown .caret {
                        border-top: 0;
                        border-bottom: 4px dashed var(--table-border-color);
                        ;
                        border-bottom: 4px solid \9;
                        content: "";
                    }

                }

                tbody {
                    tr {
                        // border-bottom: 1px solid var(--table-border-td-bg-color);
                        font-weight: 600;
                        &:last-child{
                            border-bottom: none;
                        }
                        td {
                            padding: 20px 18px;

                            .img-group {
                                text-transform: uppercase;
                                a{
                                    text-decoration: none;
                                    color: var(--analytic-table-text);
                                }
                                img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 50%;
                                }

                                .img2 {
                                    margin-left: -6px;
                                }
                            }

                            text-align: right;

                            &:nth-child(1) {
                                text-align: left;
                            }
                        }
                        &:nth-child(even) {
                            background: var(--analytic-table-even-td);
                        }
                    }
                }
            }

            .table td {
                border: none;
            }

            .table th {
                border-top: none;
            }

            .table-bordered th,
            .table-bordered td {
                border: none;
            }
        }

        .react-bootstrap-table-pagination {
            padding: 10px 40px;
            color: var(--analytic-table-pagination-text);
            margin: 0;

            .react-bootstrap-table-pagination-total {
                margin-left: 10px;
            }

            .show.react-bs-table-sizePerPage-dropdown ul:last-child {
                display: block;
            }
        }

        .show.react-bs-table-sizePerPage-dropdown ul:last-child {
            display: block;
        }
    }
}