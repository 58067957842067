.header {
  display: grid;
  padding: 0.75rem;

  .brand {
    justify-self: left;
    cursor: pointer;
    
    img {
      width: 50px;
      height: 50px;
    }
  }

  * {
    grid-column-start: 1;
    grid-row-start: 1;
    justify-self: center;
  }

  .connect-box {
    justify-self: right;
    display: flex;
  }
}
