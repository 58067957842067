.header-main {
  z-index: 99;
  flex-shrink: 0;

  header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 0px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background: var(--header-bg-color);
    box-shadow: var(--header-box-shadow);
    -webkit-box-shadow: var(--header-box-shadow);
    -moz-box-shadow: var(--header-box-shadow);


    @media (max-width: $xlg-width3) {
      padding: 0px 20px;
    }

    .logout {
      margin-left: 1rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    .logo-block {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tvl-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;
        background: var(--tvl-bg);
        padding: 8px 10px;
        border-radius: 10px;
        font-size: 0.8em;
        font-weight: 600;
        color: var(--tv-text);
        @media (max-width: $xlg-width3) {
          padding: 8px 5px;
        }
        .dot {
          margin: 5px 0px 5px 10px;
          color: var(--tv-text);
          width: 30px;
          height: 10px;
          --d: radial-gradient(farthest-side, currentColor 90%, #0000);
          background: var(--d), var(--d), var(--d);
          background-size: 5px 5px;
          background-repeat: no-repeat;
          animation: m 1s infinite alternate;
        }

        @keyframes m {
          0% {
            background-position: calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 50%
          }

          20% {
            background-position: calc(0*100%/2) 0, calc(1*100%/2) 50%, calc(2*100%/2) 50%
          }

          40% {
            background-position: calc(0*100%/2) 100%, calc(1*100%/2) 0, calc(2*100%/2) 50%
          }

          60% {
            background-position: calc(0*100%/2) 50%, calc(1*100%/2) 100%, calc(2*100%/2) 0
          }

          80% {
            background-position: calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 100%
          }

          100% {
            background-position: calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 50%
          }
        }
      }
    }
  }

  .notifi-header {
    position: absolute;
    background: var(--notifi-bg);
    width: 100%;
    color: var(--notifi-text);
    top: 58px;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  .logo {
    max-width: 180px;
    @media (max-width: $xlg-width3) {
      max-width: 135px;
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    margin: 0;

    li {
      list-style: none;
      color: var(--text-color);

      .nav-icon {
        font-size: 12px;
        margin-left: 4px;
        cursor: pointer;
      }

      a {
        position: relative;
        text-decoration: none;
        font-size: 1em;
        margin-left: 18px;
        font-weight: 500;
        color: var(--text-color);
        padding: 20px 0;

        i {
          font-size: 1.2em;
        }

        @media (max-width: $xlg-width3) {
          font-size: 13px;
        }
      }

      .btn {
        display: inline-block;
        padding: 6px 20px;
        font-size: 1em;
        font-weight: 600;
        border-radius: 50px;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
        color: var(--header-button-text);
      }

      .btn-dex-success {
        background: var(--header-xdc-button);
        color: var(--header-xdc-button-text);
        width: 100%;
      }

      .btn-dex-danger {
        background: var(--btn-danger-bg);
        color: var(--btn-danger-text);
        width: 100%;
      }

      .btn-dex-gradient {
        background: var(--header-button-bg);

        &:hover {
          background: var(--header-button-bg-hover);
        }
      }

      .btn-dex-gradient-outline {
        background: var(--body-bg-color);
        padding: 8px 10px;

        &:hover {
          background: var(--body-bg-color);
        }
      }

      .list-g {
        display: none;
        background: var(--header-dropdown-bg);

        li {
          text-align: center;
          z-index: 9;

          &:hover {
            background: var(--header-dropdown-bg-hover);
            color: var(--header-dropdown-text-color);

            a {
              display: block;
              color: var(--header-dropdown-text-color);

            }
          }

          a {
            display: block;
            padding: 15px 20px;
            margin: 0;

            img {
              width: 30px;
              margin-right: 5px;
            }
          }
        }

        .lang-active {
          background: var(--header-dropdown-bg-hover);
          color: var(--header-dropdown-text-color) !important;

          a {
            color: var(--header-dropdown-text-color) !important;

          }
        }
      }

      .lang-switch {
        overflow: hidden;
        height: 250px;
        overflow-y: scroll;

        li {
          a {
            padding: 5px 20px !important;

            img {
              width: 20px !important;
              margin-right: 8px;
            }
          }
        }
      }

      .xdc-connect-wrapper {
        background: var(--header-button-bg) !important;
        padding: 4px;
        border-radius: 50px;
        color: var(--text-color);
        margin: 0 10px;
      }

      .theme-button {
        font-size: 18px;
        cursor: pointer;
      }

      &:nth-child(6) {
        margin-right: 8px;
      }

      .wallet-status-container {
        display: flex;
        align-items: center;
        color: var(--walletStatus-text);
        background: var(--walletStatus-bg);
        height: 36px;
        padding: 12px 3px 12px 12px;
        font-size: 18px;
        border-radius: 20px;
        font-weight: 600;

        @media (max-width: $lg-width) {
          display: none;
        }
        @media (max-width: $xlg-width3) {
          font-size: 14px;
          padding: 8px 3px 8px 8px;
        }

        .account {
          margin-left: 10px;
          background: var(--walletStatus-account-bg);
          color: var(--walletStatus-text-acc);
          height: 30px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 20px;
          cursor: pointer;

          .account-inner {
            a {
              margin: 0;
              color: var(--walletStatus-text-acc);
            }
          }
        }
      }
    }

    .active {
      ul {
        display: flex;
        position: absolute;
        flex-direction: column;
        padding: 0;
        margin-left: 20px;
        z-index: 1;
      }
    }
  }
}

@media (max-width: 991px) {
  .header-main {
    .navigation {
      display: none;

      li {
        width: 100%;
        padding: 15px 20px;
        text-align: center;

        .btn-dex-gradient-outline {
          width: 100%;
        }

        a {
          font-size: 1em;
          display: inline-block;
        }

        &:hover {
          background: var(--bg-mob-nav-hover);
          color: var(--bg-mob-nav-hover-text);
        }
      }

      .active {
        ul {
          display: flex;
          position: relative;
        }
      }
    }

    .navigation.active {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--body-bg-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;

      li {
        &:hover {
          background: none;
        }
      }
    }

    header {
      padding: 20px 20px;
    }
  }

  .toggle-navigation {
    position: relative;
    width: 32px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      border-radius: 5px;
      background: var(--bg-nav-burger);
      transform: translateY(-10px);
      box-shadow: var(--bg-nav-burger-shadow);
      -webkit-box-shadow: var(--bg-nav-burger-shadow);
      -moz-box-shadow: var(--bg-nav-burger-shadow);
      transition: 0.25s;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      border-radius: 5px;
      background: var(--bg-nav-burger);
      transform: translateY(10px);
      transition: 0.25s;
    }
  }

  .toggle-navigation.active {
    position: fixed;
    right: 20px;

    &::before {
      transform: translateY(0px) rotate(45deg);
      box-shadow: none;
    }

    &::after {
      transform: translateY(0px) rotate(-45deg);
    }
  }

  .navigation {
    overflow: auto;

    li {
      a {
        margin-left: 5px;

        img {
          width: 30px;
          margin-right: 5px;
        }
      }
    }
  }
}