.coin-wrapper {
    position: relative; 
    left: 0; 
    top: 0;
    
    .image-top {
        position: relative;
        top: 0;
        left: 0;
    
        img{
            width: 25px;
        }
    }
    
    .image-bottom {
        position: absolute;
        top: 8px;
        left: 16px;
    
        img{
            width: 35px;
        }
    }
}

.pair-name{
    position: relative;
    top: -16px;
    margin-left: 60px;
    font-weight: 600;
    color: var(--coinPair-text);
}
