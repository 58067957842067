.stake-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;



    .stats {
        margin: 20px 0;
        &-counter {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            @media (max-width: $lg-width) {
                justify-content: center;
            }
        }

        &-data {
            background: #FFF;
            border-radius: 10px;
            padding: 30px 40px;
            margin: 5px;
            display: flex;
            flex-direction: column;

            align-items: center;
            height: 130px;
            width: 220px;
            @media (max-width: $md-width) {
                justify-content: center;
                width: 100%;
            }
        }

        .title,
        .counts {
            font-size: 0.9rem;
            font-weight: 500;
        }

        .title {
            color: var(--text-primary-color);

        }

        .counts {
            color: var(--text-accent-color);
        }
    }

    .stake-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 12px;

        @media (max-width: $lg-width) {
            flex-direction: column;
        }

        .details {
            background: #FFF;
            width: 49%;
            border-radius: 10px;
            height: 300px;
            max-height: 300px;

            @media (max-width: $lg-width) {
                width: 100%;
                margin-bottom: 20px;
                height: auto;
            }

            .title {
                font-size: 22px;
                padding: 15px 18px;
            }

            ul {
                list-style: none;
                padding: 10px 30px 10px 30px;
                font-size: 16px;
                color: #04051d;

                li {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 5px;

                    .status {
                        padding: 2px 20px;
                        color: #FFF;
                        border-radius: 30px;
                        font-weight: 600;
                    }
                }
            }

            .amount {
                padding: 20px;
                display: flex;
                justify-content: space-between;
                font-weight: 600;
                color: var(--text-primary-color);
            }

            .stake-input-group {
                padding: 20px;

                input {
                    display: flex;
                    width: 100%;
                    height: 46px;
                    border-radius: 20px;
                    border: 2px solid var(--text-accent-color);
                    padding: 0 20px;
                    margin-bottom: 20px;

                    &:focus-visible {
                        outline: none;
                    }
                }

                .btn-stake {
                    background: var(--text-accent-color);
                    padding: 10px 40px;
                }
            }
        }

    }
}