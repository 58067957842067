.custom-modal-1 {
  .modal-content {
    background-color: transparent;
    border: none;

    .modal-body {
      padding: 0;
      background-color: transparent;


      .settings {
        background: var(--modal-setting-bg);
        border-radius: 30px;
        padding: 20px;

        .field {
          .label {
            display: flex;
            color: var(--modal--slippage-field-label);

            .popover-box {
              margin-left: 6px;

              .qs {
                background-color: var(--text-accent-color);
                border-radius: 20px;
                color: var(--modal-setting-popover-text);
                cursor: default;
                display: inline-block;
                font-size: 12px;
                font-weight: bold;
                height: 20px;
                line-height: 20px;
                position: relative;
                text-align: center;
                width: 20px;

                .close-icon {
                  display: flex;
                  justify-content: right;
                }

                .popover {
                  background-color: var(--modal-setting-popover-bg);
                  border-radius: 5px;
                  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
                  color: var(--modal-setting-popover-text);
                  display: none;
                  font-size: 12px;
                  padding: 7px 10px;
                  position: absolute;
                  width: 250px;
                  z-index: 4;
                  margin: 16px;
                }

                &:hover {
                  .popover {
                    display: block;
                    -webkit-animation: fade-in .3s linear 1, move-up .3s linear 1;
                    -moz-animation: fade-in .3s linear 1, move-up .3s linear 1;
                    -ms-animation: fade-in .3s linear 1, move-up .3s linear 1;
                  }
                }
              }

              @-webkit-keyframes fade-in {
                from {
                  opacity: 0;
                }

                to {
                  opacity: 1;
                }
              }

              @-moz-keyframes fade-in {
                from {
                  opacity: 0;
                }

                to {
                  opacity: 1;
                }
              }

              @-ms-keyframes fade-in {
                from {
                  opacity: 0;
                }

                to {
                  opacity: 1;
                }
              }

              @-webkit-keyframes move-up {
                from {
                  bottom: 30px;
                }

                to {
                  bottom: 42px;
                }
              }

              @-moz-keyframes move-up {
                from {
                  bottom: 30px;
                }

                to {
                  bottom: 42px;
                }
              }

              @-ms-keyframes move-up {
                from {
                  bottom: 30px;
                }

                to {
                  bottom: 42px;
                }
              }
            }
          }

          .value {
            input {
              background: var(--modal-setting-bg-input);
              color: var(--modal-slippage-input);
              font-size: 18px;
              font-weight: 600;
            }

            select {
              float: right;
              border-radius: 5px;
              background: var(--modal-setting-bg-input);
              color: var(--modal-slippage-input);
              font-size: 18px;
              font-weight: 600;
              border: none;
            }
          }
        }

        .save {
          .btn-modal-button {
            background: var(--modal-slippage-button-bg);
            color: var(--modal-slippage-button-text);
            width: 100%;
            border-radius: 20px;
            margin-top: 20px;
            text-align: center;
            padding: 10px;
          }
        }

      }

      .acknowledgeFee {
        background: var(--modal-ack-bg);
        color: var(--modal-ack-text);
        border-radius: 10px;
        padding: 10px;

        &__note {
          text-align: right;
        }

        .adjust-btn {
          background: var(--modal-slippage-button-bg);
          color: var(--modal-slippage-button-text);
          width: 100%;
          border-radius: 20px;
          margin-top: 20px;
          text-align: center;
          padding: 10px;
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .custom-modal-1 {
    .modal-content {
      .modal-body {
        .settings {
          padding: 10px 4px;
        }
      }
    }
  }

}