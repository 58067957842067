.tradingview-widget-container {
  #tv_chart_container {
    width: 100%;
    height: 100%;
  }
  display: inline-block;
  height: 500px;
  width: 100%;
  @media (max-width: 992px) {
    height: 400px;
  }
}
