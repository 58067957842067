.token-load-confirm {
  .iri{
    background-color: white;
    color: #9c3e5c;
    width: 300px;
    padding: 1rem;
    border-radius: 1rem;

    button {
      background: #f6e4ed;
      color: #f85797;
      border: none;
      outline: none;
      width: 100%;
      border-radius: 20px;
      margin-top: 20px;
      text-align: center;
      padding: 10px;
    }
  }

  .light{
    background-color: white;
    color: #0db2d7;
    width: 300px;
    padding: 1rem;
    border-radius: 1rem;

    button {
      background: #0db2d7;
      color: #FFF;
      border: none;
      outline: none;
      width: 100%;
      border-radius: 20px;
      margin-top: 20px;
      text-align: center;
      padding: 10px;
    }
  }

  .dark{
    background-color: #1e1e1e;
    color: #FFF;
    width: 300px;
    padding: 1rem;
    border-radius: 1rem;

    button {
      background: #C38FFF;
      color: #FFF;
      border: none;
      outline: none;
      width: 100%;
      border-radius: 20px;
      margin-top: 20px;
      text-align: center;
      padding: 10px;
    }
  }

  @extend .custom-modal-1;

  .load-token {
    font-size: large;
    font-weight: 500;
    text-transform: uppercase;
  }

  .note,
  .list,
  .footer {
    margin: 10px;
  }

  li {
    .token {
      display: flex;
      flex-grow: 1;
    }

    .action {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-right: 5px;
    }

    display: flex;
    padding: 5px;
    border-bottom: 1px solid #d3d3d3;

    .logo,
    .body,
    .balance {
      margin: 0 5px 0 5px;
    }

    .body {
      flex-grow: 1;
      .name {
        font-size: small;
        color: #808080;
      }
    }

    .balance {
      display: grid;
      align-items: center;
      margin-right: 10px;
      img {
        width: 15px;
      }
    }

    .logo {
      height: 40px;
      width: 40px;
    }

    &:hover {
      background-color: #d3d3d3;
    }
  }

  
}
