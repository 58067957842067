.__dark {
  --background-gradient-1: #928dab;
  --background-gradient-2: #1f1c2c;

  --header-button-background: #4e4376;
  --header-button-background--hover: darken(#4e4376, 10%);
  --header-button-color: white;
  --header-button-border-color: darken(#4e4376, 50%);

  --text-primary-color: #000;
  --text-accent-color: #C38FFF;
  --text-secondary-color: #C38FFF;

  --background-primary: #04051d;
  --background-accent: #C38FFF;
  --background-secondary: #C38FFF;



  //landing bg
  --body-bg-color: #000;
  --header-bg-color: transparent;
  --header-box-shadow: none;
  --text-color: #fff;
  --header-button-bg: linear-gradient(90deg, #C38FFF, #328bf0);
  --header-button-text: #C38FFF;
  --header-button-bg-hover: #fff;
  --header-xdc-button: #ffffff;
  --header-xdc-button-text: #2a2e97;

  //landing Page colors
  --landing-content-margin-bt:12.5rem;
  --landing-dex-text-h1: #FFF;
  --landing-dex-text-h3: #FFF;
  --landing-dex-text-align: center;
  --landing-dex-text-weight: 100;
  --color-text-light-blue: #C38FFF;
  --color-text-yellow: #ffc512;

  //Header dropdown Colors
  --header-dropdown-bg: #000;
  --header-dropdown-bg-hover: #202020;
  --header-dropdown-text-color: #fff;

  //Mobile Nav bg
  --bg-mob-nav-hover: #C38FFF;
  --bg-mob-nav-hover-text: #fff;
  --bg-nav-burger: #fff;
  --bg-nav-burger-shadow: 0 10px 0 #fff;

  //landing page layout
  --body-text: #FFF;
  --main-hero-text: center;
  --main-hero-text-align: center;
  --lptoken-text-align: center;
  --swap-token-padding: 180px 80px;
  --swap-content-margin: 160px;

  //large device layout
  --main-hero-text-large: space-evenly;

  //Swap Button pill colors
  --bg-pill-button: #1e1e1e;
  --pill-button-text: #FFF;
  --swap-button-bg: #C38FFF;
  --token-box-bg: #2f2f2f;
  --token-box-border: 1px solid #131313;
  --token-box-border-focus: 1px solid #C38FFF;
  --input-text-bg: #2f2f2f;

  //Swap text
  --title-text-color: #FFF;
  --subTitle-text-color: #555469;
  --swap-text-input: #FFF;

  // swap icons color
  --swap-icon-color: #C38FFF;

  //swap-modal
  --modal-border: #555469;
  --modal-bg-color: #000;
  --modal-border-color: 1px solid #555469;
  --modal-list-hover: #272a7370;
  --modal-box-shadow: 0px 2px 4px rgba(195, 143, 255, 0.66);

  //pool
  --pool-search-placeholder: #000;
  --pool-card-btn-bg-pair: #C38FFF;
  --pool-card-btn-bg-info: #C38FFF;
  --pool-action-bg: #fff6;
  --pool-search-icon-input-color: #000;

  //pool info
  --poolinfo-liquidity-bg: #1e1e1e;
  --poolinfo-locked-token-bg: #faf9fa;
  --poolinfo-pagination-bg: #C38FFF;
  --poolinfo-pagination-text: #ffff;
  --poolinfo-chart-button-bg: #000;
  --poolinfo-chart-button-text: #FFF;
  --poolinfo-chart-button-active: #C38FFF;
  --poolinfo-table-bg-body: #1e1e1e;
  --poolinfo-table-text: #fff;
  --poolinfo-table-body-text:#FFF;
  --poolinfo-table-border: #1e1e1e;
  --poolinfo-table-shadow: 0px 0px 6px 3px rgba(195, 143, 255, 0.5);
  --poolinfo-table-stripped: #272727;
  --poolinfo-token-shadow: rgba(42, 47, 152, 0.603) 0px 1px 1px,
    rgba(42, 47, 152, 0.753) 0px 1px 1px,
    rgba(42, 47, 152, 0.466) 0px 0px 0px 1px inset;
  --poolinfo-stats-bg: #1e1e1e;
  --poolinfo-stats-text: #FFF;
  --poolinfo-stats-label: #C38FFF;
  --poolinfo-stats-locked-token-bg: #252525;
  --poolinfo-stats-locked-token-text: #FFF;
  --poolinfo-btn-bg-outline: #C38FFF;
  --poolinfo-btn-bg: #C38FFF;
  --poolinfo-btn-text-outline: #FFF;
  --pool-card-bg:#1e1e1e;
  --pool-card-title-text:#FFF;
  --pool-card-remove:#FFF;

  //tokenlist
  --addToken-bg: #0a0c40c2;

  //footer
  --footer-bg: #000;
  --footer-border: #2a2e97;
  --footer-copyright-text: #fff;
  --footer-social-icons-border: 3px solid #fff;
  --footer-social-icons-border-res: 2px solid #fff;

  //header image
  --header-bg-img: url("../../dex/dark/galaxy-main.png");
  --xrc-content-img: url("../../dex/dark/galaxy-skew.png");
  --lp-token-content-img: url("../../dex/dark/moon.png");

  //modal slippage
  --modal--slippage-field-label: #FFF;
  --modal-slippage-input: #C38FFF;
  --modal-slippage-button-bg: #C38FFF;
  --modal-slippage-button-text: #fff;

  //analytics
  --bar-chart-day-text: #FFF;
  --bar-chart-day-text-active: #FFF;
  --bar-chart-text-heading: #FFF;
  --table-text-color: #FFF;
  --table-border-td-bg-color: #000000;
  --table-border-color: #FFF;
  --analytic-table-head: #C38FFF;

  //tvl
  --tvl-bg: #1e1e1e;
  --tv-text: #C38FFF;

  //dex box stats
  --bg-dex-counter: #0c0c0c;
  --bg-dex-counter-border: #000;
  --dex-text-color: #b4b4b4;

  //toggle
  --toggle-bg: #2f2f2f;
  --toggle-text: #C38FFF;

  --tokenPrice-hover:#C38FFF;
  --tokenPrice-text-hover: #FFF;

  
  --learnMore-bg:#C38FFF;
  --learnMore-text:#fff;

  --faq-main-text:#C38FFF;
  --faq-title-text:#C38FFF;
  --faq-list-text:#FFF;
  --faq-icon-bg:#C38FFF;
  --faq-box-shadow:rgba(18, 113, 142, 0.11) 0px 0px 4px 0px inset, rgba(218, 218, 218, 0.25) 0px 50px 100px -20px, rgba(114, 229, 249, 0.055) 0px 30px 60px -30px;
  --faq-section-bg:#1e1e1e;
  --faq-link:#3737ff;

  --toast-progress-color:#C38FFF;
  --input-placeholder-text:gray;

  
  --chart-loaderbg-1:#c38fffa8;
  --chart-loaderbg-2:#c38fffa8;
  --chart-loaderbg-3:#c38fffa8;

  --custom-skeleton--highlight: #c38fff4b;
  --base-color-bg:#3f3f3f;
  --custom-skeleton--bg: #3f3f3f;
  --custom-skeleton--bh: #ebebeb;

  --coinPair-text:#FFF;
  --btn-text:#FFF;
  --dark-landing-box:#202020;
  --btn-dex-dark-solid-bg:#FFF;
  --btn-dex-dark-solid-text: #C38FFF;

  --notifiy-info-bg:#808080;
  --notifiy-info-text:#ffffff;
  --notifiy-success-bg:#808080;
  --notifiy-success-text:#ffffff;
  --notifiy-warning-bg:#808080;
  --notifiy-warning-text:#ffffff;
  --notifiy-processing-bg:#808080;
  --notifiy-processing-text:#ffffff;
  --notifiy-error-bg:#808080;
  --notifiy-error-text:#ffffff;

  --pool-action-btn-bg:#c7c1c1;
  --pool-action-btn-text:#000;
  --pool-action-btn-a-bg:#ffffff;
  --pool-action-btn-a-border:#d3d3d3;

  --btn-danger-bg:#ec4134;
  --btn-danger-text:#ffff;

  --modal-setting-bg:#1e1e1e;
  --modal-ack-bg:#1e1e1e;
  --modal-ack-text:#FFF;
  --modal-setting-popover-text:#FFF;
  --modal-setting-popover-bg:#000000d9;
  --modal-setting-bg-input:#1e1e1e;
  --analytic-table-bg:#1e1e1e;
  --analytic-table-even-td:#272727;
  --analytic-table-pagination-text:#fff;

  --token-header-bg: #1e1e1e;
  --token-header-text: #c38fff;
  --token-header-hvr-text: #FFF;
  --token-content-bg:#FFF;


  --token-load-border:#d3d3d3;
  --token-load-body-name-text:#808080;
  --token-load-body-name-hover:#d3d3d3;

  
  --token-list-custom-action-text:#FFF;
  --token-list-custom-list-border:#d3d3d3;
  --token-list-custom-list-body-name-text:#808080;
  --token-list-custom-list-add-text:#ffffff;
  --token-list-custom-list-no-token-list:#ffffff;
  --token-list-custom-list-active-bg:#f5f5f5;

  
  --swap-bg:#1e1e1e;
  --swap-input-placeholder:#808080;
  --swap-subtext:#808080;
  --swap-inversrate-text:#808080;
  --swap-route-text:#fff;
  --swap-route-path-bg:#000000d9;
  --swap-route-path-text:#fff;
  --swap-route-vtext-text:#4241417d;
  --swap-route-coinchain-bg:#645e60;
  --swap-route-coinchain-text:#FFF;
  --swap-route-dot:#FFF;
  --swap-submit-text:#FFF;
  --swap-submit-border:#d3d3d3;
  --swap-field-bg:#f5f5f5;
  --swap-field-border:#d3d3d3;

  --poolcard-content-bg:#f0f8ff;
  --poolcard-content-value-text:#000;
  --poolcard-content-remove:#ff0000;
  --poolcard-content-btn-text:#FFF;
  
  --poolrow-bg:#1e1e1e;
  --poolrow-content-text:#fff;
  --poolrow-border: #d3d3d3;
  --poolrow-pair-btn-text:#ffffff;
  --poolrow-remove-btn:#ff0000;

  --poolInfo-btn-text: #FFF;
  --poolInfo-amt-usd-text: #04051d;

  --pool-active-btn: #FFF;
  --display-view-btn-bg: #FFF;
  --display-view-btn-text: #000 ;
  --display-view-active-btn: #C38FFF;

  --pool-info-radio-bg-color:#FFF;
  --pool-info-radio-color:#C38FFF;
  --pool-info-filter-text:#FFF;
  --poolinfo-btn-text-outline-dark: #C38FFF;
  --poolManage-balance-bg:#1e1e1e;
  --poolManage-balance-token-bg:#353535;
  --poolManage-balance-token-text:#C38FFF;
  --poolManage-lpbalance-input-text:#C38FFF;
  --poolManage-lpbalance-input-border:#C38FFF;
  --poolManage-connect-btn-bg:#C38FFF;
  --poolManage-connect-btn-text:#FFF;
  --poolManage-connect-btn-border:#0000;

  --token-info-tooltip-text:#C38FFF;
  --token-info-tab-button-text:#000;
  --token-info-tab-button-active-text:#C38FFF;
  --token-info-stats-text:#C38FFF;

  --clipboard-bg:#1e1e1e;
  --clipboard-text:#C38FFF;

  --token-btn:#1e1e1e;
  --token-btn-text:#FFF;
  --token-btn-hvr:#C38FFF;
  --token-btn-hvr-text:#FFF;

  --notifi-bg:#c38fff5d;
  --notifi-text:#FFF;

  --error-page-bt-text:#FFF;
  --error-page-bt-bg:#1e1e1e;
  --error-page-bt-shadow: 0px 0px 6px 3px rgba(255, 255, 255, 0.466);

  --filter-switch-chart: #FFF;
  --analytic-table-text:#FFF;

  --token-info-stats-text1:#FFF;
  --token-info-tab-button-active:#1e1e1e;
  --manage-pool-text-color: #FFF;

  --link-color-main: #8383ff;
  --link-color-main-hvr: #1000ff;

  --token-bottom-color:#000;

  --walletStatus-bg: #C38FFF;
  --walletStatus-account-bg: #1e1e1e;
  --walletStatus-text: #FFF;
  --walletStatus-text-acc: #FFF;
  --walletStatus-footer-bg:#232323;
  --walletStatus-theme:#FFF;

  --maintenance-text:#FFF;

}