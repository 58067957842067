.pool {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  margin-top: 8rem;

  .search,
  .pool-container {
    padding: 1rem;
  }

  .action-bar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem;
    @media screen and (max-width: $xxsm-width) {
      padding: 0;
    }
    .actions {
      display: flex;
      background: var(--pool-action-bg);
      border-radius: 50px;
      padding: 6px;
      margin: 10px 0;
      button,
      a {
        margin: 0 5px 0 5px;
        background: var(--pool-action-btn-bg);
        border-radius: 50px;
        color: var(--pool-action-btn-text);
        border: none;
        svg.active{
          color: var(--pool-active-btn);
        }
      }

      a {
        padding: 0.5rem;
        background-color: var(--pool-action-btn-a-bg);
        border: 1px solid var(--pool-action-btn-a-border);
        border-radius: 50px;
        text-decoration: none;
      }

      .display-view-btn {
        background: var(--display-view-btn-bg);
        color: var(--display-view-btn-text);
        svg.active{
          color: var(--display-view-active-btn);
        }
      }


    }

    .search-wrapper {
      .icon-group-input {
        .fa-icon {
          position: absolute;
          margin: 18px 10px;
          color: var(--pool-search-icon-input-color);
        }
        input {
          border: none;
          border-radius: 50px;
          padding: 12px;
          text-align: right;
          width: 350px;
          height: 100%;
          &::placeholder {
            color: var(--pool-search-placeholder);
          }
          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }

  .pool-container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
  }
}
