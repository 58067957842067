.landing-wrapper {
  color: var(--body-text);
  z-index: 10;

  p {
    font-size: 1.2em;
  }

  .main {
    position: relative;
    min-height: 100vh;
    background-image: var(--header-bg-img);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 80px 0px 80px;
    width: 100%;

    @media (max-width: $lg-width) {
      background-position: center;
    }

    @media (max-width: $xlg-width2) {
      background-position: 50% -85%;
    }
  }

  .content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: var(--main-hero-text);
    align-items: center;
    text-align: var(--main-hero-text-align);
    margin-bottom: var(--landing-content-margin-bt);

    @media (min-width: 1480px) {
      justify-content: var(--main-hero-text-large);
    }

    @media (max-width: $lg-width) {
      margin-bottom: 0;
    }

    .dex-text {
      width: 100%;
      max-width: 600px;
      margin-top: 40px;

      h1 {
        font-size: 5.4em;
        font-weight: 700;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
        text-align: var(--landing-dex-text-align);
        color: var(--landing-dex-text-h1);
      }

      .dex-subtitle {
        font-size: 1.4em;
        font-weight: var(--landing-dex-text-weight);
        letter-spacing: 0px;
        line-height: 32px;
        margin-bottom: 20px;
        text-align: var(--landing-dex-text-align);
        color: var(--landing-dex-text-h3);
      }
    }

    .dark {
      width: 100%;
      max-width: 600px;
      margin-top: 40px;

      @media (max-width: $lg-width) {
        margin-top: 120px;
      }
    }

    .app-image {
      width: 100%;
      max-width: 550px;
      margin-top: 60px;

      img {
        width: 100%;
      }
    }
  }

  //dark
  .dark-dex {
    position: absolute;
    top: 80%;
    width: 65%;

    @media (max-width: $xlg-width2) {
      width: 70%;
    }

    @media (max-width: $lg-width) {
      position: unset;
      margin-top: 120px;
      width: 40%;
    }

    @media (max-width: $sm-width) {
      width: 80%;
    }

    .dark-box {
      padding: 30px;
      background: var(--dark-landing-box);

      @media (max-width: $lg-width) {
        padding: 20px;
      }
    }
  }

  .text-light-blue {
    color: var(--color-text-light-blue);
  }

  .text-yellow {
    color: var(--color-text-yellow);
  }

  .btn {
    display: inline-block;
    padding: 12px 20px;
    font-size: 1em;
    font-weight: 600;
    border-radius: 50px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    color: var(--btn-text);
  }

  .btn-dex-gradient {
    background: $bg-button-color;

    &:hover {
      background: $bg-button-hover-color;
    }
  }

  .swap-token {
    background-image: var(--xrc-content-img);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--swap-token-padding);

    .swap-content {
      // position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: var(--swap-content-margin);

      @media (min-width: 1460px) {
        justify-content: var(--main-hero-text-large);
      }

      .ring-planet {
        background-image: url("../../dex/planet-ring.png");
      }

      .swap-wrapper-text {
        width: 100%;
        max-width: 500px;
        margin-top: 30px;
      }

      .swap-token-p {
        padding: 0 10px 20px 0;
        font-size: 1.4em;
      }

      .app-image {
        width: 100%;
        max-width: 700px;
        margin-top: 30px;

        img {
          width: 100%;
        }
      }
    }
  }

  .swap-token-title {
    h2 {
      font-size: 2.6em;
      margin-bottom: 20px;
    }
  }

  .dex-hr {
    margin: 100px;
    border: none;
    border-bottom: $color-hr-tag;
  }

  .dex-text-center {
    text-align: center;
  }

  .dex-project-cmp-pd {
    padding: 14px 160px;
  }

  .dex-counting-boxes {
    display: flex;
    flex-direction: row;
    
    align-items: center;
    // margin-bottom: 40px;
  }

  .light{
    justify-content: center;
  }

  .dark {
    justify-content: center;
  }
  .dex-single-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-dex-counter);
    max-width: 220px;
    width: 100%;
    padding: 30px 0px;
    border-radius: 5px;
    border: var(--bg-dex-counter-border);
    margin: 6px;

    .dex-img-counter {
      width: 100%;
      max-width: 80px;
      margin-bottom: 10px;
    }

    .counting-text {
      font-size: 1.4em;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .desc-text {
      text-transform: capitalize;
      font-size: 0.9em;
      color: var(--dex-text-color);
      font-weight: 300;
    }
  }

  .gb-bg {
    &-light {
      // position: relative;
      background-image: var(--lp-token-content-img);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 150px 80px 60px 80px;
      // margin: -80px 0;
      // z-index: 0;

      @media (max-width: $lg-width) {
        padding: 20px;
      }

      .content-pd {
        padding: 120px 0 150px 0;
      }
    }

    &-dark {
      // position: relative;
      background-image: var(--lp-token-content-img);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 150px 80px 60px 80px;
      // margin: -80px 0;
      // z-index: 0;

      @media (max-width: $lg-width) {
        padding: 20px;
      }

      .content-pd {
        padding: 120px 0 150px 0;
      }
    }

  }

  .gb-bg-iri {
    background: var(--landing-gb-bg);
    padding: 60px 120px 80px 120px;

    @media (max-width: $lg-width) {
      padding: 20px;
    }

    .content-pd {
      padding: 0;

      .gb-token-text {
        margin: 0;
      }

      .gb-token-image {
        margin: 0;
      }
    }
  }

  .gb-token-text {
    width: 100%;
    max-width: 700px;
    margin-top: 60px;
    padding: 20px;
    text-align: var(--lptoken-text-align);
  }

  .gb-token-image {
    width: 100%;
    max-width: 500px;
    margin-top: 60px;

    img {
      width: 60%;
    }
  }

  .text-iri-dark {
    color: var(--text-iri-dark);
  }

  .text-iri-light {
    color: var(--text-iri-light);
  }

  //iri layout css

  .smart-chain {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
    align-items: center;
    background: var(--landing-gb-bg);
    padding: 80px 120px 60px 120px;

    @media (max-width: $lg-width) {
      padding: 20px;
      grid-template-columns: none;
      grid-template-rows: auto auto;
      justify-content: center;
    }

    .wallet-img {
      max-width: 400px;

      @media (max-width: $lg-width) {
        margin: auto;
      }

      img {
        width: 100%;
      }
    }

    .main-text-title {
      max-width: 600px;
      padding: 20px;

      .main-title {
        font-weight: 600;

      }

      p {
        font-size: 1.2em;
        margin: 30px 0 60px 0;
      }

      .button-group {
        .btn {
          &-dex-iri {
            &-solid {
              margin-right: 5px;
            }
          }
        }
      }
    }

  }




  .supply-stats {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 60px;
    justify-content: center;
    background: var(--landing-gb-bg);
    padding: 30px 120px;

    @media (max-width: $lg-width) {
      grid-template-columns: none;
      grid-template-rows: auto auto auto auto;
      padding: 60px 20px;
    }

    .supply-data {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0px 80px 0 10px;
      border-right: 1px solid var(--supply-boder);

      &:last-child() {
        border-right: none;
      }

      @media (max-width: $xlg-width2) {
        padding: 0px 60px 0 10px;

      }

      @media (max-width: $lg-width) {
        border-bottom: 1px solid var(--supply-boder);
        border-right: none;

        &:last-child() {
          border-bottom: none;
        }
      }

      .supply-img {
        height: 50px;
        width: 50px;
        background: var(--supply-img-bg);
        border-radius: 50px;
        text-align: center;
        padding: 6px;

        img {
          width: 36px;
        }
      }

      .number {
        margin: 10px 0 0 0;
        line-height: 40px;
        font-size: 1.5em;
        font-weight: 600;
      }

      .smaller-text {
        font-size: 0.9em;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }

  .call-to-action {
    background-image: var(--call-action-bg-img);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;

    .cta-text {
      padding: 120px 120px 100px 120px;

      @media (max-width: $lg-width) {
        padding: 120px 20px 100px 20px;
      }

      p {
        padding: 30px 0 50px 0;
      }

      .btn-dex-iri-outline {
        margin-left: 10px;

        @media (max-width: $lg-width) {
          margin-top: 10px;
        }
      }
    }
  }


  .btn-dex-dark-solid {
    background: var(--btn-dex-dark-solid-bg);
    color: var(--btn-dex-dark-solid-text) !important;
  }

  .btn-dex-iri-solid {
    background: var(--btn-dex-iri-solid-bg);
  }

  .btn-dex-iri-outline {
    color: var(--btn-dex-iri-outline-text) !important;
    outline: 2px solid var(--btn-dex-iri-outline-text);
    background: transparent;
  }

  .btn-dex-iri-solid-white {
    background: var(--btn-dex-iri-solid-white-bg);
    color: var(--btn-dex-iri-solid-white-text) !important;

  }

  .main-title {
    font-size: 2.6em;
    line-height: 50px;
  }
}

.mb-40 {
  margin-bottom: 40px;
}

.loader-dot {
  margin: 5px 0px 5px 10px;
  color: var(--tv-text);
  width: 30px;
  height: 10px;
  --d: radial-gradient(farthest-side, currentColor 90%, #0000);
  background: var(--d), var(--d), var(--d);
  background-size: 5px 5px;
  background-repeat: no-repeat;
  animation: m 1s infinite alternate;
}

@keyframes m {
  0% {
    background-position: calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 50%
  }

  20% {
    background-position: calc(0*100%/2) 0, calc(1*100%/2) 50%, calc(2*100%/2) 50%
  }

  40% {
    background-position: calc(0*100%/2) 100%, calc(1*100%/2) 0, calc(2*100%/2) 50%
  }

  60% {
    background-position: calc(0*100%/2) 50%, calc(1*100%/2) 100%, calc(2*100%/2) 0
  }

  80% {
    background-position: calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 100%
  }

  100% {
    background-position: calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 50%
  }
}


//smaller screen 992px
@media (max-width: 992px) {
  .landing-wrapper {
    .main {
      padding: 20px;
    }

    .content {
      flex-direction: column-reverse;

      .dex-text {
        h1 {
          font-size: 2.4em;
        }

        .dex-subtitle {
          font-size: 1em;
          line-height: 20px;
        }
      }
    }

    .swap-token {
      padding: 20px;

      .swap-content {
        flex-direction: column;

        .swap-token-p {
          font-size: 1em;
        }
      }
    }

    .swap-token-title {
      h2 {
        font-size: 1.7em;
      }
    }

    .dex-hr {
      margin: 4em 1.5em;
    }

    .dex-project-cmp-pd {
      padding: 20px;
    }

    .dex-counting-boxes {
      flex-direction: column;
    }

    .gb-bg {
      padding: 0px 20px 60px 20px;
    }

    .gb-token-image {
      text-align: center;
    }
  }

  //for larger screen size 1460

}

@media (max-width: 600px) {
  .landing-wrapper {
    .swap-token-title {
      h2 {
        font-size: 1.4em;
      }
    }
    .btn{
      font-size: 0.8rem;
      padding: 10px 12px;
      font-weight: 400;
    }

    .main-text-title{
      .main-title {
        font-size: 1.4em;
        line-height: 25px;
      }
    }
    .smart-chain .main-text-title p {
        font-size: 1em;
    }

    .call-to-action{
      .cta-text{
        .main-title{
          font-size: 1.6em;
        }
        p{
          font-size: 1em;
        }
      }
    }
  }
}