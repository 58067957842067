.loader-box {
  background: currentcolor;
  position: relative;
  -webkit-animation: contentLoader 1s ease-in-out infinite;
  animation: contentLoader 1s ease-in-out infinite;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  width: 0.25em;
  height: 0.5em;
  margin: 0 0.5em;
}
.box {
  width: 26px;
  display: inline-table;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loader-box {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background: currentcolor;
  color: currentcolor;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin-left: 10px;
}

.loader-box::before,
.loader-box::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.loader-box::before {
  left: -10px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background: currentcolor;
  color: currentcolor;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.loader-box::after {
  left: 10px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background: currentcolor;
  color: currentcolor;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}
