.learn-more-container {
  margin: 8rem 0rem;
  width: 100%;
  padding: 10px 120px;
  @media (max-width: $lg-width) {
    padding: 0px;
  }
  .text-container {
    border-radius: 10px;
    background: var(--learnMore-bg);
    color: var(--learnMore-text);
    padding: 30px;
    margin: 40px;
    @media (max-width: $lg-width) {
      padding: 20px;
      margin: 0px;
    }
  }

  .highlight {
    font-weight: 500;
  }
}
