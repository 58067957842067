*::after,
::before {
  /* Basic Reset */
  margin: 0px;
  padding: 0px;
}

body.modal-open {
  overflow: auto !important;
  padding-right: 0px !important;
}


::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(77, 75, 75);
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 85, 85);
}

  

