.navbar {
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 2px 5px 2px 5px;
  display: flex;
  flex-wrap: wrap;

  &__nav-item {
    @extend .no-select;
    @extend .no-outline;    
  
    padding: 0.25rem 1rem 0.25rem 1rem;
    margin: 0.25rem 0.5rem 0.25rem 0.5rem;
    border-radius: 5px;
    text-transform: capitalize;
    border: 1px solid transparent;
    color: var(--header-button-color);
    text-decoration: none;
    background-color: var(--header-button-background);

    &:hover {
      color: var(--header-button-color);
      border: 1px solid var(--header-button-border-color);
      background-color: var(--header-button-background--hover);
      text-decoration: none;
    }

    &.active {
      background-color: lightgrey;
      font-weight: 600;
    }
  }
}
